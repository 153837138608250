import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { getBalances } from '../APIs';

import { FiGift } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";

import { supabase } from '../auth/client';
import NavMenu from './NavMenu';

function NavBar(props) {
    const [walletBalance, setWalletBalance] = useState(null)

    const [firstLoad, setFirstLoad] = useState(true)

    // used for nav menu opened by hamburger btn on mobile
    const [menuVisible, setMenuVisible] = useState(false);

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()
    let location = useLocation().pathname.substring(1)

    if (location.endsWith('/')) {
        location = location.slice(0, -1);
    }

    const handleToggle = () => {
        setMenuVisible(prev => !prev);
    };
    
    const fetchData = async () => {
        setIsLoading(true)
        const response = await getBalances({token: props.session.access_token})

        if (response.status === 401) {
            props.setSession(null)
            setWalletBalance(0)
            props.setPromoBalance(0)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setWalletBalance(parseFloat(response.walletBalance.$numberDecimal))
        props.setPromoBalance(parseFloat(response.promoBalance.$numberDecimal))
        setIsLoading(false)
        return
    };

    const handleDepositBtnClick = () => {
        if (location === 'myentries') {
            props.setOpen(props.isVerified)
        } else{
            props.setOpen(props.isVerified)
            navigate('/myentries')
        }
    }

    const logout = async () => {
        props.setSession(null)
        setWalletBalance(0)
        props.setPromoBalance(0)
        const { error } = await supabase.auth.signOut()
        navigate('/login')
    }

    useEffect(() => {
        if (props.session) {
            fetchData()
        }
        setFirstLoad(false)
    }, [props.session, location])

    return (
        <AppBar sx={{ bgcolor: '#0B1215', boxShadow: 'none', height: {xs:'54px', md:'82px'}, 
            display: 'flex', justifyContent: 'center',
            pl: {xs: 0, sm:'12px', lg:'0 !important'}, pr: {xs: '12px !important', xl: '332px !important'}, width: {
                xs: '100%', 
                lg: 'calc(100% - 300px)', 
                xl: 'calc(100% - 320px)'
            }, boxSizing: 'border-box'  
        }}>
        <Container sx={{ height: {xs:'54px', md:'82px'}, mx: 0, boxSizing: 'border-box', maxWidth: 'none !important', px: '0 !important', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', boxSizing: 'border-box', minHeight: '0 !important' }} disableGutters>

            {/* START Hamburger when screen is small*/}
            <Box sx={{ display: { xs: 'flex', lg: 'none' }, mr: 0, justifyContent: 'center' }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <MenuIcon sx={{ fontSize: {xs:'36px', sm: '42px'}  }}/>
                </IconButton>
                
                <Box onClick={() => navigate('/')} sx={{ display: {xs:'none', md:'flex'}, justifyContent: 'center', alignItems: 'center', gap: '4px', cursor: 'pointer', ml: '16px' }}>
                    <img src='/logo.svg' height={'48px'}/>
                    <Typography style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '32px', color: 'white' }} >AGENTPICKS</Typography>
                </Box>
                <Box onClick={() => navigate('/')} sx={{ display: {xs:'flex', md:'none'}, justifyContent: 'center', alignItems: 'center', gap: '0px', cursor: 'pointer' }}>
                    <Box 
                        component="img" 
                        src="/logo.svg" 
                        sx={{ 
                            height: '32px', 
                            '@media (max-width: 400px)': { height: '30px' },
                            '@media (max-width: 350px)': { display: 'none' } 
                        }} 
                    />
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '22px', color: 'white', '@media (max-width: 400px)': { fontSize: '20px' } }} >
                        AGENTPICKS
                    </Typography>
                </Box>
            </Box>
            {/* END Hamburger when screen is small*/}

            {/* START Logo + Title when screen is big*/}
            <Box
                sx={{
                    display: { xs: 'none', lg: 'flex' },
                    textDecoration: 'none',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'row',
                    gap: '12px'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <a href="/howtoplay" rel="noopener noreferrer" style={{ textDecorationColor: 'white' }}>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'12px', md:'14px', lg:'16px'}, color: 'white', textDecoration: 'underline', cursor: 'pointer' }} >
                            HOW TO PLAY
                        </Typography>
                    </a>
                    <div style={{ borderRight: '2px solid rgba(168, 0, 0, 0.8)',  height: '24px', margin: '0 10px' }} ></div>
                    <a href="https://esportsagent.gg/support" target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: 'white' }}>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'12px', md:'14px', lg:'16px'}, color: 'white' }} >
                            SUPPORT CENTER
                        </Typography>
                    </a>
                </Box>
            </Box>
            {/* END Logo + Title when screen is big*/}


            {/* START Right Corner profile buttons when screen is big */}
            {(firstLoad && !props.session) || typeof(props.session) == 'undefined' ?
                <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', width: '244px' }}>
                </Box>
            :
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '100%',
                        flexDirection: 'row',
                        gap: '16px',
                        boxSizing: 'border-box',
                    }}
                >
                    {props.session ? 
                        isLoading && walletBalance === null ?
                            null
                        :
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '8px', p: '4px', boxSizing: 'border-box', gap: '8px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '30px', gap: '20px', px: '16px', boxSizing: 'border-box', }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', boxSizing: 'border-box', }}>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '20px', color: '#267800', mb: '2px' }}>$</Typography>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '16px', color: 'white', ml: '3px' }}>{walletBalance > 0 ? walletBalance.toFixed(2) : '0.00'}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', boxSizing: 'border-box', }}>
                                            <FiGift color='rgba(168, 0, 0, 0.8)' fontSize={21} style={{ marginBottom: '-1px' }}/>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '16px', color: 'white', ml: '4px' }}>{props.promoBalance > 0 ? props.promoBalance.toFixed(2) : '0.00'}</Typography>
                                        </Box>
                                    </Box>
                                    <Box 
                                        onClick={() => handleDepositBtnClick()} 
                                        sx={{ 
                                            display: 'flex', 
                                            cursor: 'pointer', 
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            '&:hover': {
                                                color: 'black',
                                                bgcolor: 'rgba(168, 0, 0, 1)',
                                            },
                                            color: 'black',
                                            width: '30px',
                                            height: '30px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '6px',
                                            boxSizing: 'border-box'
                                        }} 
                                    >
                                        <IoAdd size={24} color='white' />
                                    </Box>
                                </Box>
                            </>
                    :
                        <Box sx={{ width: '244px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', boxSizing: 'border-box', gap: '16px' }}>
                            <Button variant="contained" onClick={() => navigate('/login')} sx={{ 
                                bgcolor: '#23292C', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Log In
                            </Button>
                            <Button variant="contained" onClick={() => window.open("https://esportsagent.gg/signup", "_blank")} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Sign Up
                            </Button>
                        </Box>
                    }
                </Box>
            }
            {/* END Right Corner profile buttons when screen is big */}

            {/* START Right Corner profile buttons when screen is small */}
            {(firstLoad && !props.session) || typeof(props.session) == 'undefined' ?
                <Box sx={{ display: { xs: 'flex', md: 'none' }, height: '100%', width: '68px' }}>
                </Box>
            :
                <Box
                    sx={{
                        display: { xs: 'flex', md: 'none' },
                        textDecoration: 'none',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    {props.session ?
                        walletBalance !== null ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '8px', p: '2px', boxSizing: 'border-box' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', height: '30px', gap: '8px', px: '8px', boxSizing: 'border-box', }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', boxSizing: 'border-box', }}>
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '18px', color: '#267800', mb: '2px'}}>$</Typography>
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '15px', color: 'white', ml: '3px', lineHeight: 0 }}>{walletBalance > 0 ? walletBalance.toFixed(2) : '0.00'}</Typography>
                                    </Box>
                                    <Box sx={{ display: {xs:'none', sm:'flex'}, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', boxSizing: 'border-box', fontSize: '18px' }}>
                                        <FiGift color='rgba(168, 0, 0, 0.8)' style={{ marginBottom: '-2px' }} />
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '15px', color: 'white', ml: '4px', lineHeight: 0 }}>{props.promoBalance > 0 ? props.promoBalance.toFixed(2) : '0.00'}</Typography>
                                    </Box>
                                </Box>
                                <Box 
                                    onClick={() => handleDepositBtnClick()} 
                                    sx={{ 
                                        display: 'flex', 
                                        cursor: 'pointer', 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)',
                                        '&:hover': {
                                            color: 'white',
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                        },
                                        color: 'white',
                                        width: '30px',
                                        height: '30px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '6px',
                                        boxSizing: 'border-box',
                                        '@media (max-width: 380px)': {
                                            display: 'none',
                                        },
                                    }} 
                                >
                                    <IoAdd size={24} color='white'/>
                                </Box>
                            </Box>
                        :
                            null
                    :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', boxSizing: 'border-box', width: 'fit-content' }}>
                            <Button variant="contained" onClick={() => navigate('/login')} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: {xs: '56px', sm: '70px'},
                                minWidth: {xs: '56px', sm: '70px'},
                                height: {xs: '32px', sm: '36px'},
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: {xs: '12px', sm: '14px'},
                                padding: {xs: '3px', sm: '4px'},
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Log In
                            </Button>
                        </Box>
                    }
                </Box>
            }
            {/* END Right Corner profile buttons when screen is small*/}

            </Toolbar>
        </Container>
        <Divider variant="fullWidth" sx={{ border: '1px solid rgba(255, 255, 255, 0.05)', boxSizing: 'border-box' }} />
        <Box sx={{ width: '100%', height: '100vh', display: {xs: 'flex', lg: 'none'}, zIndex: 998, 
            position: 'absolute', top: 0, right: 0, boxSizing: 'border-box', pl: {xs:'-8px', sm:'-12px', md:'-16px'}, 
            minWidth: '280px', transform: menuVisible ? 'translateX(0)' : 'translateX(-100%)', transition: 'transform 0.3s ease-in-out'
        }}>
            <NavMenu session={props.session} setSession={props.setSession} setMenuVisible={setMenuVisible} menuVisible={menuVisible} handleDepositBtnClick={handleDepositBtnClick} promoBalance={props.promoBalance} walletBalance={walletBalance} />
        </Box>
        </AppBar>
    );
}
export default NavBar;
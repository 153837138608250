import { useEffect, useState, useLayoutEffect } from "react";

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import GameBtn from "../components/GameBtn";
import PicksCart from "../components/PicksCart";
import CartBtn from "../components/CartBtn";
import BoardCard from "../components/BoardCard";
import SearchBar from "../components/SearchBar";


import { FaStar } from "react-icons/fa";
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { BsPersonBadge } from 'react-icons/bs';

import { getBoard, getBoardWithSearch } from "../APIs";
import MobilePicksCart from "../components/MobilePicksCart";

import BarLoader from "react-spinners/BarLoader";


function BoardPage(props) {
    const [boardData, setBoardData] = useState(null);
    const [game, setGame] = useState("COD");
    const [cartOpen, setCartOpen] = useState(false);
    const [selectedPicks, setSelectedPicks] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const [errorMsg, setErrorMsg] = useState({});

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const fetchData = async () => {
        setIsLoading(true)

        const data = await getBoard()

        if (data.status !== 200) {
            setIsLoading(false)
            return
        }

        const groupedData = data.board.reduce((acc, playerData) => {
            // Extract the date part (YYYY-MM-DD) from matchDateTimeEST
            const matchDate = playerData.matchDateTimeEST.substring(0, 10);

            const key = `${playerData.name}-${playerData.team}-${matchDate}`
            const gameKey = playerData.game;
            if (!acc[gameKey]) {
                acc[gameKey] = {};
            }
            if (!acc[gameKey][key]) {
                acc[gameKey][key] = [];
            }
            acc[gameKey][key].push(playerData);
            return acc;
        }, {});
        
        // if currently selected game is on board
        if (groupedData.hasOwnProperty(game)) {
            setBoardData(groupedData[game])
            setIsFirstLoad(false)
        } 
        else {
            const games = Object.keys(groupedData)
            // if board is empty
            if (games.length === 0) {
                setBoardData([])
                setIsFirstLoad(false)
            }
            // if currently selected game is not on board and board is not empty
            else {
                const firstGame = games[0]
                setGame(firstGame)
                setBoardData(groupedData[firstGame])
            }
        }

        return
    }

    const fetchData2 = async () => {
        setIsLoading(true)

        const data = await getBoard()

        if (data.status !== 200) {
            setIsLoading(false)
            setIsFirstLoad(false)
            return
        }

        const groupedData = data.board.reduce((acc, playerData) => {
            // Extract the date part (YYYY-MM-DD) from matchDateTimeEST
            const matchDate = playerData.matchDateTimeEST.substring(0, 10);

            const key = `${playerData.name}-${playerData.team}-${matchDate}`
            const gameKey = playerData.game
            if (!acc[gameKey]) {
                acc[gameKey] = {}
            }
            if (!acc[gameKey][key]) {
                acc[gameKey][key] = []
            }
            acc[gameKey][key].push(playerData);
            return acc
        }, {})
        
        // if currently selected game is on board
        if (groupedData.hasOwnProperty(game)) {
            setBoardData(groupedData[game])
        }
        else {
            setBoardData([])
        }

        setIsLoading(false)
        setIsFirstLoad(false)
        return
    }

    // Check if value is empty, null, undefined, or NaN
    const isValidValue = (value) => {
        return value !== null && value !== undefined && value !== '';
    }

    const fetchData3 = async () => {

        // Validate the query parameter
        if (typeof searchInput !== 'string' || searchInput.length > 20 || !/^[a-zA-Z0-9-]+$/.test(searchInput)) {
            return
        }

        setIsLoading(true)

        const userData = {
            query: searchInput
        }

        const data = await getBoardWithSearch(userData)

        if (data.status !== 200) {
            setIsLoading(false)
            return
        }

        const groupedData = data.board.reduce((acc, playerData) => {
            // Extract the date part (YYYY-MM-DD) from matchDateTimeEST
            const matchDate = playerData.matchDateTimeEST.substring(0, 10);

            const key = `${playerData.name}-${playerData.team}-${matchDate}`
            const gameKey = playerData.game
            if (!acc[gameKey]) {
                acc[gameKey] = {}
            }
            if (!acc[gameKey][key]) {
                acc[gameKey][key] = []
            }
            acc[gameKey][key].push(playerData);
            return acc
        }, {})
        
        // if currently selected game is on board
        if (groupedData.hasOwnProperty(game)) {
            setBoardData(groupedData[game])
        }
        else {
            setBoardData([])
        }

        setIsLoading(false)
        return
    }

    // runs only once (defaults game to whatever game has picks)
    useLayoutEffect(() => {
        fetchData()
    }, [])

    // gets boardData for currently selected game
    useLayoutEffect(() => {
        if (!isValidValue(searchInput)) {
            fetchData2()
        }
    }, [game, searchInput])

    // gets boardData based on searchbar (0.5s delay before querying)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchInput && isValidValue(searchInput)) {
                fetchData3()
            }
        }, 500); // Delay of 0.5 seconds

        return () => clearTimeout(timer)
    }, [searchInput]);

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
            const timer = setTimeout(() => {
                setErrorMsg({})
            }, 5000)

            return () => clearTimeout(timer);
        }
    }, [errorMsg])


    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                minHeight: '100vh', 
                width: {
                    xs: '100%', 
                    lg: 'calc(100% - 300px)', 
                    xl: 'calc(100% - 320px)'
                },
                mt: {xs:'54px', md:'82px'},
                boxSizing: 'border-box',
                position: 'relative',
                pb: '130px',
                pr: {
                    xs: '0px',
                    xl: '320px'
                },
            }}
        >
            {errorMsg && errorMsg.msg ? 
                <Alert sx={{ 
                    position: 'fixed', top: '100px', width: {xs:'80%', lg:'auto'}, 
                    zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }

            {/*<Divider variant="fullWidth" sx={{ border: '1px solid rgba(255, 255, 255, 0.05)', boxSizing: 'border-box', mb: '2vh' }} />*/}
                
            {!isFirstLoad ?
                <>
                    <Box sx={{display: cartOpen ? {xs:'none', md: 'flex'} : 'flex', flexDirection: 'column' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            position: {xs: 'sticky', md: 'static'} ,
                            top: {xs:'54px', md:'82px'},
                            bgcolor: '#0B1215',
                            zIndex: {xs: '9', md: '1'},
                            flexDirection: 'column',
                            pb: {xs:'4px', md:'8px'} 
                        }}>
                            <Box
                                sx={{
                                    display: cartOpen ? {xs: 'none', md:'flex'} : 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    height: '44px',
                                    alignItems: 'center',
                                    gap: '16px',
                                    bgcolor: '#0B1215',
                                    pt: {xs:'8px', md:'16px'},
                                }}
                            >
                                <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                                <CartBtn setCartOpen={setCartOpen} cartOpen={cartOpen} pickCount={selectedPicks.length} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box' }}>
                                <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'start', mt: {xs:'8px', md:'16px'}, overflowX: 'scroll', pb: {xs:'4px', md:'8px'} }}>
                                    <GameBtn gameTitle="COD" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="HALO" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="WZ" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="CS2" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="VAL" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="APEX" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="LOL" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="RL" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="DOTA2" game={game} setGame={setGame} />
                                    <GameBtn gameTitle="R6" game={game} setGame={setGame} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', border: 1, borderColor: 'rgba(255, 255, 255, 0.1)', bgcolor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px', textAlign: 'left', minHeight: '52px', alignItems: 'center', px: {xs:'10px', md:'20px'}, py: {xs:'5px', sm:'0px'}, }}>
                            <Box sx={{ display: 'flex', mr: {xs:'10px', md:'20px'}, minWidth: '16px', minHeight: '16px', boxSizing: 'border-box', mb: '3px' }} >
                                <FaStar style={{ color: 'gray', width: '100%' }} />
                            </Box>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'11px', lg:'13px', xl: '14px'}, color: 'white', lineHeight: 1.1, mt: '1px' }} >
                                PICK 2-6 PLAYERS. CHOOSE WHETHER YOU THINK THEY WILL GET MORE OR LESS THAN THEIR PROJECTION. DNP AND PUSHES ARE CALCULATED AS IF THE PLAYER WAS NEVER PICKED.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', mt: '24px', boxSizing: 'border-box' }} >
                            <Box 
                                sx={{ 
                                    display: 'inline-flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    gap: '16px', 
                                    boxSizing: 'border-box', 
                                    width: {xs: '100%', md: cartOpen? '65%' : '100%'}
                                }}
                            >
                                {!isLoading && !isFirstLoad && boardData && Object.entries(boardData).map(([key, playerBetsArr]) => (
                                    <Box key={key} sx={{ width: {xs: '300px', md: '365px' } }}>
                                        <BoardCard playerBetsArr={playerBetsArr} selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks} setCartOpen={setCartOpen} setErrorMsg={setErrorMsg} />
                                    </Box>
                                ))}
                                {!isLoading && !isFirstLoad && (!boardData || (boardData && Object.keys(boardData).length === 0)) ? (
                                    <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, color: 'white', fontSize: { xs: '22px', lg: '28px' }, mt: '64px' }} >
                                        NO UPCOMING MATCHES
                                    </Typography>
                                ) : null}
                                {isLoading || isFirstLoad ? (
                                    <Box sx={{ mt: '76px' }}>
                                        <BarLoader
                                            color="rgba(168, 0, 0, 1)"
                                            loading="true"
                                            height={10}
                                            width={260}
                                            data-testid="loader"
                                        />
                                    </Box>
                                ) : null}
                            </Box>
                            <Box sx={{ width: cartOpen ? 'auto' : '0px', display: {xs:'none', md:'block'}, boxSizing: 'border-box', ml: '16px', height: '100%' }}>
                                {
                                    cartOpen ? 
                                        <PicksCart setErrorMsg={setErrorMsg} selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks} setCartOpen={setCartOpen} cartOpen={cartOpen} session={props.session} setSession={props.setSession} validLocation={props.validLocation} detectedState={props.detectedState} />
                                    :
                                        null
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: cartOpen ? {xs: 'flex', md: 'none'} : 'none', width: '100%', height: '100%' }}>
                        <MobilePicksCart setErrorMsg={setErrorMsg} selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks} setCartOpen={setCartOpen} cartOpen={cartOpen} session={props.session} setSession={props.setSession} validLocation={props.validLocation} detectedState={props.detectedState}/>
                    </Box>
                    <Box sx={{ bgcolor: 'black', position: 'fixed', bottom: 0, left: 0, width: '100%', height: '60px', display: {xs:'flex', md:'none'}, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: '99', }}>
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px dashed #A80000', height: '90%', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px' }}>
                                {selectedPicks.map((bet, index) => (
                                    <FaUser color="#A80000" size={18} key={index} />
                                ))}
                            </Box>
                            <Typography sx={{ lineHeight: 1, fontSize: '16px', fontFamily: 'Plus Jakarta Sans', fontWeight: 700, mx: '10px', mt: selectedPicks.length > 0 ? '5px' : 0, color: 'white' }}>{selectedPicks.length} SELECTED</Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button 
                                onClick={() => setCartOpen(!cartOpen)}
                                sx={{ 
                                    width: '100%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    flexDirection: 'column' 
                                }}
                            >
                                {cartOpen ?
                                    <>
                                        <BsPersonBadge color='white' size={20}/>
                                        <Typography sx={{ color: 'white', lineHeight: 1, fontSize: '16px', fontFamily: 'Plus Jakarta Sans', fontWeight: 700, mt: '5px' }}>Board</Typography>
                                    </>
                                :
                                    <>
                                        <FaShoppingCart color='white' size={20}/>
                                        <Typography sx={{ color: 'white', lineHeight: 1, fontSize: '16px', fontFamily: 'Plus Jakarta Sans', fontWeight: 700, mt: '5px' }}>Cart</Typography>
                                    </>
                                }
                            </Button>
                        </Box>
                    </Box>
                </>
            :
                null
            }
        </Box>
    )
}
  
export default BoardPage;
import { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { HiFire } from "react-icons/hi";

import BoardCardBet from './BoardCardBet';
import Logo from './Logo';

import moment from 'moment-timezone';

function BoardCard(props) {

    const [expanded, setExpanded] = useState(false)
    const [readyForAnimations, setReadyForAnimations] = useState(false)

    const totalPicked = props.playerBetsArr.reduce((sum, item) => sum + item.picked, 0)
    const extra = props.playerBetsArr.length - 4
    const sample = props.playerBetsArr[0]

    let size = 348

    if (extra > 0) {
        size = ((57 * extra) + 348)
    }

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };


    useEffect(() => {
        const timer = setTimeout(() => {
          setReadyForAnimations(true)
        }, 400)
    }, [])

    return (
        <animated.div style={useSpring({
            height: expanded ? `${size}px` : '348px',
            from: { height: '348px' },
            immediate: !readyForAnimations
        })}>
            <Box sx={{ 
                width: {xs: '300px', md: '365px' },
                height: '100%',
                overflow: 'hidden',
                borderRadius: '8px', 
                border: 1, 
                borderColor: 'rgba(255, 255, 255, 0.1)',
                bgcolor: 'rgba(255, 255, 255, 0.05)', 
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                pb: expanded ? '49px' : 0
            }} >
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: '10px', pl: '8px', pr: {xs:'8px', md:'10px'}, justifyContent: 'flex-start', height: '80px', boxSizing: 'border-box', minHeight: '80px', position: 'relative' }}>
                    <Box sx={{ height: '64px', maxWidth: '64px', width: '64px', minWidth: '64px' }}>
                        <Logo teamColor={sample.img} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: {xs:'6px', md:'8px'}, pr: {xs:'0px', md:'4px'}, justifyContent: 'start', pt: {xs:0, md:'2px'}, width: '100%', gap: '1px' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                fontSize: {
                                    xs: sample.name.includes(' + ') ? '16px' : '18px',
                                    md: sample.name.includes(' + ') ? '18px' : '20px',
                                },
                                color: 'white',
                                lineHeight: 1.1,
                                textAlign: 'left',
                                maxWidth: {xs:'170px' , md:'235px'}
                            }}
                        >
                            {sample.name.toUpperCase()}
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '15px', color: 'rgba(255, 255, 255, 0.8)', mt: {xs:'-1px', md:'-2px'} }}>{sample.team}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', gap: {xs:'2px', md:'4px'}, mt: {xs:'-1px', md:'-2px'} }}>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>{convertToDate(sample.matchDateTimeEST).slice(0, -5)}</Typography>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>at {convertToTime(sample.matchDateTimeEST)}</Typography>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>vs {sample.opp.toUpperCase()}</Typography>
                        </Box>
                    </Box>
                    {totalPicked && totalPicked >= 15 ?
                        <Box sx={{ position: 'absolute', top: 6, right: 12, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
                            <HiFire color='orange' size={16}/>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '14px', color: 'whitesmoke' }} >{totalPicked}</Typography>
                        </Box>
                    :
                        null
                    }
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', gap: '17px', mt: '12px' }} >
                    {props.playerBetsArr.map((singleSpecificBet) => (
                        <BoardCardBet key={singleSpecificBet._id} bet={singleSpecificBet} selectedPicks={props.selectedPicks} setSelectedPicks={props.setSelectedPicks} setCartOpen={props.setCartOpen} setErrorMsg={props.setErrorMsg} />
                    ))}
                </Box>
                {
                    props.playerBetsArr.length > 4 && !expanded &&
                        <Box onClick={() => setExpanded(!expanded)} 
                            sx={{ display: 'flex', 
                                flexDirection: 'row', boxSizing: 'border-box', 
                                justifyContent: 'center', cursor: 'pointer', 
                                position: 'absolute', bottom: 0, width: '100%', 
                                height: '40px', bgcolor: '#23292C', zIndex: 2, 
                                alignItems: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.1)', 
                                '&:hover': {backgroundColor: '#303030'} 
                            }}
                        >
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white' }} >MORE PICKS ({props.playerBetsArr.length - 4}) <MdExpandMore size={24} style={{ marginBottom: -6 }}/> </Typography>
                        </Box>
                }
                {
                    props.playerBetsArr.length > 4 && expanded &&
                        <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', 
                            flexDirection: 'row', boxSizing: 'border-box', 
                            justifyContent: 'center', cursor: 'pointer', 
                            position: 'absolute', bottom: 0, width: '100%', 
                            height: '40px', bgcolor: '#23292C', zIndex: 2, 
                            alignItems: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.1)', 
                            '&:hover': {backgroundColor: '#303030'} 
                        }}>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '16px', color: 'white' }} >LESS PICKS <MdExpandLess size={24} style={{ marginBottom: -6 }}/> </Typography>
                        </Box>
                }

            </Box>
        </animated.div>
    );
}
    
export default BoardCard;
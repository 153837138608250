import { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import { MdExpandLess, MdExpandMore } from "react-icons/md";
import EarningsGraph from './EarningsGraph';

function PrestigePromotionCard(props) {

    const [expanded1, setExpanded1] = useState(false)

    const [readyForAnimations, setReadyForAnimations] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
          setReadyForAnimations(true)
        }, 500)
    }, [])

    return (
        <animated.div style={useSpring({
            height: expanded1 ? '720px' : '300px',
            from: { height: '300px' },
            immediate: !readyForAnimations
        })}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'start', 
                boxSizing: 'border-box', 
                bgcolor: '#171E21',
                width: {xs:'340px', lg:'350px'}, 
                borderRadius: '8px',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                border: '1px solid rgba(255, 255, 255, 0.1)',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', boxSizing: 'border-box', width: '100%', px: '16px', height: '184px', py: '28px', minHeight: '184px' }} >
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                        PRESTIGE MEMBERSHIP ($14.99/MO)
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1 }}>
                            Get 1 free 6-pick entry per week (to win $20)
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1, textAlign: 'left' }}>
                            Boost winnings by 5%
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1 }}>
                            Cannot be combined with other boosts
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1 }}>
                            Includes all EsportsAgent.gg benefits
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', boxSizing: 'border-box', gap: '12px', height: '56px', minHeight: '56px' }}>
                    {/* <Button variant="contained" onClick={() => window.open("https://esportsagent.gg", "_blank")} sx={{ 
                        bgcolor: '#252525', 
                        color: 'white',
                        width: '120px',
                        height: '44px',
                        '&:hover': {
                            backgroundColor: '#303030',
                        },
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 500,
                        fontSize: '12px',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        lineHeight: 1.1,
                        px: '4px'
                    }}>
                        Learn More
                    </Button> */}
                    {props.member ?
                        null
                    :
                        <Button variant="contained" onClick={() => window.open("https://esportsagent.gg/store", "_blank")} sx={{ 
                            bgcolor: 'rgba(168, 0, 0, 0.8)', 
                            color: 'white',
                            width: '120px',
                            height: '44px',
                            '&:hover': {
                                backgroundColor: 'rgba(168, 0, 0, 1)',
                            },
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '13px',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                            lineHeight: 1.1,
                            px: '4px'
                        }}>
                            JOIN TODAY
                        </Button>
                    }
                </Box>
                {props.member ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', boxSizing: 'border-box', width: '100%', px: '16px', height: '440px', py: '12px', minHeight: '440px' }} >
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start', mb: '12px' }}>
                            Prestige Membership Bonus Earnings
                        </Typography>
                        <EarningsGraph data={props.monthlyMEarnings} />
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '14px', color: 'white', alignSelf: 'center', mt: '12px' }}>
                            Last 6 Month Earnings: ${(props.monthlyMEarnings.reduce((sum, item) => sum + item.amt, 0)).toFixed(2)}
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '14px', color: 'white', alignSelf: 'center' }}>
                            Total Earnings: ${props.membershipEarnings.toFixed(2)}
                        </Typography>
                    </Box>
                :
                    null
                }
                {props.member ?
                    <Box onClick={() => {setExpanded1(!expanded1)}}
                        sx={{ display: 'flex', 
                            flexDirection: 'row', boxSizing: 'border-box', 
                            justifyContent: 'center', cursor: 'pointer', 
                            position: 'absolute', bottom: 0, width: '100%', 
                            height: '42px', bgcolor: '#23292C', zIndex: 2, 
                            alignItems: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.1)', 
                            '&:hover': {backgroundColor: '#303030'} 
                        }}
                    >
                        {expanded1 ?
                            <>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                                    HIDE EARNINGS
                                <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                </Typography>
                            </>
                        :
                            <>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                                    SHOW EARNINGS
                                <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                                </Typography>
                            </>
                        }
                    </Box>
                :
                    null
                }
            </Box>
        </animated.div>
    )
}

export default PrestigePromotionCard
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

import { ReactComponent as boardIcon } from '../images/board.svg';
import { ReactComponent as myentriesIcon } from '../images/myentries.svg';
import { ReactComponent as promotionsIcon } from '../images/promotions.svg';
import { FaQuestion as howIcon} from "react-icons/fa";
import { FaHeadset as support } from "react-icons/fa6";
import { IoMdSettings as settings} from "react-icons/io";

function NavBtn2(props) {
  const isSelected = props.curr === props.page;

  const navigate = useNavigate()

  const components = {
        'BOARD': boardIcon,
        'MY ENTRIES': myentriesIcon,
        'PROMOTIONS': promotionsIcon,
        'HOW TO PLAY': howIcon,
        'SUPPORT CENTER': support,
        'SETTINGS': settings
    }

    const SelectedIcon = components[props.page]

  return (
    <Button
        onClick={() => {
            if (props.page === 'SUPPORT CENTER') {
                window.open('https://esportsagent.gg/support', '_blank', 'noopener noreferrer');
            } else {
                props.setMenuVisible(false)
                navigate(props.to)
            }
        }}
        sx={{
            textTransform: 'none',
            width: '100%',
            height: '48px',
            fontSize: '16px',
            fontWeight: 'regular',
            fontFamily: 'Poppins',
            display: 'flex', 
            justifyContent: 'flex-start',
            px: '18px',
            color: isSelected ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
            borderRadius: '8px',
            bgcolor: '#23292C',
            boxShadow: isSelected ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
            border: isSelected ? '0.5px solid rgba(168, 0, 0, 0.5)' : '0.5px solid #23292C',

            ...(isSelected && {
            ':before': {
                content: '""',
                position: 'absolute',
                top: '12%',
                bottom: '12%',
                left: '-1px',
                width: '3px',
                bgcolor: 'rgba(168, 0, 0, 1)',
                borderRadius: '4px',
            },
            }),

            ':hover': {
                bgcolor: '#23292C',
                boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                color: 'rgba(168, 0, 0, 1)',
                border: '0.5px solid rgba(168, 0, 0, 0.5)'
            },
        }}
    >
        {props.page === 'SUPPORT CENTER' || props.page === 'HOW TO PLAY' || props.page === 'SETTINGS' ?
            <Box sx={{ maxWidth: '48px', minWidth: '48px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '22px'}}>
                <SelectedIcon />
            </Box>
        :
            <Box sx={{ maxWidth: '48px', minWidth: '48px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SelectedIcon />
            </Box>
        } 
        {props.page}
    </Button>
  );
}

export default NavBtn2;

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

function ProgressBar(props) {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: {xs:'92%', sm:'85%'} }}>
        <Typography sx={{ visibility: props.finished && props.actual !== -1 ? 'visible' : 'hidden', fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: {xs:'12px', sm:'13px'}, color: props.win === 1 ? 'rgb(28, 197, 0)' : props.win === 0 ? 'white' : 'rgba(168, 0, 0, 0.8)', 
          textAlign: 'end', 
          width: `${Math.min((props.actual / props.predicted) * 100, 100)}%`,
          pl: '4px',
          ...(Math.min((props.actual / props.predicted) * 100, 100) === 100 ? { width: 'calc(100% - 4px)' } : {}),
        }}>
          {props.actual !== null && props.actual.toString().includes('.') ? props.actual : `${props.actual}.0`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', width: {xs:'98%', xl:'99%'}, mt: '8px', mb: '9px' }}>
            <Box sx={{
              position: 'absolute',
              left: 0,
              width: props.actual !== null ? `${Math.min((props.actual / props.predicted) * 100, 100)}%` : '100%',
              zIndex: 2,
              height: '100%',
              display: 'flex',
              alignItems: 'center'
            }} >
              <Box
                sx={{
                  bgcolor: props.win === 1 ? 'rgb(28, 197, 0)' : props.win === -1 ? 'rgba(168, 0, 0, 0.8)' : props.actual === props.predicted ? 'darkgray' : '#434343',
                  width: `100%`,
                  height: '8px',
                  borderRadius: '90px 0 0 90px',
                  zIndex: 2,
                  position: 'relative',
                  alignItems: 'center',
                  color: 'white'
                }}
              >
                {props.win !== 0 ?
                  <Box sx={{ 
                    bgcolor: props.win === 1 ? 'rgb(28, 197, 0)' : 'rgba(168, 0, 0, 0.8)', 
                    width: '2px', height: '20px', borderRadius: '90px 90px 90px 90px', 
                    position: 'absolute', right: props.actual === 0 ? '-14px' : 0, bottom: '-6px'
                  }} />
                :
                  props.actual === null ?
                    <Box sx={{ 
                      bgcolor: '#434343', width: '2px', height: '16px', borderRadius: '90px 90px 90px 90px', position: 'absolute', right: 0, bottom: '-4px'
                    }} />
                  :
                    null
                }
              </Box>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                bgcolor: '#434343',
                width: '100%',
                height: '8px',
                borderRadius: '90px 0 0 90px',
              }}
            />
            <Box sx={{ 
              borderRight: props.actual !== null && props.actual === props.predicted ? '2px solid darkgray' : '2px solid #434343', 
              height: '18px', 
              position: 'absolute', 
              right: 0, 
            }} />

        </Box>
        {props.actual === -1 ?
          <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: {xs:'12px', sm:'13px'}, color: 'white', textAlign: 'end', mr: props.predicted < 10 ? '1px' : 0 }}>
            DNP
          </Typography>
        :
          <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: {xs:'12px', sm:'13px'}, color: 'white', textAlign: 'end', mr: props.predicted < 10 ? '1px' : 0 }}>
            {props.predicted.toString().includes('.') ? props.predicted : `${props.predicted}.0`}
          </Typography>
        }
    </Box>
  )
}

export default ProgressBar
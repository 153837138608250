import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import CCPromotionCard from '../components/CCPromotionCard';
import FriendPromotionCard from '../components/FriendPromotionCard';
import PrestigePromotionCard from '../components/PrestigePromotionCard';
import { getPromotions, getPromotions2 } from '../APIs';

import { supabase } from '../auth/client';
import PromotionsSkeleton from '../skeletons/PromotionsSkeleton';

function PromotionsPage(props) {

  const [creatorCode, setCreatorCode] = useState("")
  const [creatorCodeEarnings, setCreatorCodeEarnings] = useState(0)
  const [monthlyCCEarnings, setMonthlyCCEarnings] = useState([])
  const [referrals, setReferrals] = useState(0)
  const [referralCode, setReferralCode] = useState("")
  const [referralEarnings, setReferralEarnings] = useState(0)
  const [monthlyREarnings, setMonthlyREarnings] = useState([])
  const [member, setMember] = useState(false)
  const [membershipEarnings, setMembershipEarnings] = useState(0)
  const [monthlyMEarnings, setMonthlyMEarnings] = useState([])

  const [errorMsg, setErrorMsg] = useState({});

  const [isLoading, setIsLoading] = useState(true)
  const [isLoading2, setIsLoading2] = useState(true)

  const navigate = useNavigate();

  // gets a user's promotions data (from users DB)
  const fetchPromotions = async () => {
    setIsLoading(true)

    const response = await getPromotions({token: props.session.access_token})
    
    if (response.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (response.status !== 200) {
      return
    }

    setCreatorCode(response.data.creatorCode)
    setCreatorCodeEarnings(parseFloat(response.data.creatorCodeEarnings.$numberDecimal))

    setReferrals(response.data.referrals)
    setReferralCode(response.data.referralCode)
    setReferralEarnings(parseFloat(response.data.referralEarnings.$numberDecimal))

    setMember(response.data.member)
    setMembershipEarnings(parseFloat(response.data.membershipEarnings.$numberDecimal))

    fetchPromotions2()

    setIsLoading(false)

    return
  }

  // gets a user's DETAILED promotions data (from users DB)
  const fetchPromotions2 = async () => {
    setIsLoading2(true)
    const response = await getPromotions2({token: props.session.access_token})
    if (response.status === 401) {
      props.setSession(null)
      //const { error } = await supabase.auth.signOut()
      //navigate('/login')
      return
    } else if (response.status !== 200) {
      return
    }

    setMonthlyCCEarnings(response.data.monthlyCCEarnings)
    setMonthlyREarnings(response.data.monthlyREarnings)
    setMonthlyMEarnings(response.data.monthlyMEarnings)
    
    setIsLoading2(false)

    return
  }

  useEffect(() => {
    if (props.session) {
      fetchPromotions()
    }
  }, [props.session])

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        minHeight: '100vh', 
        width: {
          xs: '100%', 
          lg: 'calc(100% - 300px)', 
          xl: 'calc(100% - 320px)'
        },
        mt: {xs:'78px', md:'104px'},
        boxSizing: 'border-box',
        position: 'relative',
        flexDirection: {xs: 'column', md: 'row'}, 
        gap: '24px',
        alignItems: {xs:'center', md:'flex-start'},
        alignContent: 'flex-start',
        flexWrap: {xs:'none', md:'wrap'},
        pb: '130px'
      }}
    >
      {errorMsg && errorMsg?.type === "other" && errorMsg?.msg ? 
        <Alert sx={{ 
          position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
          zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
          border: '2px solid #d32f2f', color: 'white', 
          fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
          alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
        }} severity="error" >
          {errorMsg?.msg}
        </Alert> 
      : null }
      {isLoading ?
        <>
          <PromotionsSkeleton/>
          <PromotionsSkeleton/>
          <PromotionsSkeleton/>
        </>
      :
        <>
          <CCPromotionCard creatorCode={creatorCode} creatorCodeEarnings={creatorCodeEarnings} monthlyCCEarnings={monthlyCCEarnings} />
          <FriendPromotionCard referrals={referrals} referralCode={referralCode} referralEarnings={referralEarnings} monthlyREarnings={monthlyREarnings} />
          <PrestigePromotionCard member={member} membershipEarnings={membershipEarnings} monthlyMEarnings={monthlyMEarnings} />
        </>
      }
    </Box>
  )
}

export default PromotionsPage
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { MdExpandLess, MdExpandMore } from "react-icons/md";
import EntrySummaryCard from './EntrySummaryCard';

import { supabase } from '../auth/client';
import { getPicks } from "../APIs";
import EntrySummarySkeleton from "../skeletons/EntrySummarySkeleton";

import moment from 'moment-timezone';

function EntrySummary(props) {

    const [picks, setPicks] = useState(null)
    const [expanded, setExpanded] = useState(props.index === 0 && props.currentPage === 1)
    const [isLoading, setIsLoading] = useState(true)
    
    // 0 = in progress, -1 = loss, +1 = win, +2 = refund
    let status = 0
    // in progress
    if (props.bet.remaining > 0) {
        status = 0
    }
    // wins
    else if (props.bet.remaining === 0 && !props.bet.free && ((parseFloat(props.bet.totalWinAmount.$numberDecimal) / parseFloat(props.bet.betAmount.$numberDecimal)) > 1.0)) {
        status = 1
    } else if (props.bet.remaining === 0 && props.bet.free && props.bet.losses === 0) {
        status = 1
    }
    // refunds
    else if (!props.bet.free && ((parseFloat(props.bet.totalWinAmount.$numberDecimal) / parseFloat(props.bet.betAmount.$numberDecimal)) === 1.0)) {
        status = 2
    } else if (!props.bet.free && props.bet.losses === 0 && props.bet.remaining === 0 && parseFloat(props.bet.totalWinAmount.$numberDecimal) === 0) {
        status = 2
    } else {
        status = -1
    }

    let multiplier = 0.00

    if (props.type === "OPEN") {
        if (props.bet.flex) {
            if (props.bet.picks.length === 4) {
                multiplier = (7.00 * (1.00 - props.bet?.reduced)) + ((7.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 5) {
                multiplier = (9.50 * (1.00 - props.bet?.reduced)) + ((9.50 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 6) {
                multiplier = (23.50 * (1.00 - props.bet?.reduced)) + ((23.50 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            }
        } else {
            if (props.bet.picks.length === 2) {
                multiplier = (3.00 * (1.00 - props.bet?.reduced)) + ((3.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 3) {
                multiplier = (5.00 * (1.00 - props.bet?.reduced)) + ((5.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 4) {
                multiplier = (10.00 * (1.00 - props.bet?.reduced)) + ((10.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } 
        }
    }

    multiplier = (Math.round(multiplier * 100) / 100);

    const navigate = useNavigate();

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };

    // gets board player data from entry/bet id
    const fetchPicks = async () => {
        setIsLoading(true)
        const response = await getPicks({
            id: props.bet._id, 
            token: props.session.access_token
        })
        
        if (response.status === 401) {
            props.setSession(null)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setPicks(response.picks)
        setIsLoading(false)

        return
    }

    useEffect(() => {
        if (props.session) {
            fetchPicks()
            return
        }
    }, [props.session])
    

    return (
        <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', cursor: 'pointer', flexDirection: 'column', p: {xs:'12px', md:'24px'}, border: '1px solid rgba(255, 255, 255, 0.1)', bgcolor: {xs:'#1E2527', md:'#171E21'}, boxSizing: 'border-box', borderRadius: '12px', '&:hover': {borderColor: 'rgba(255, 255, 255, 0.2)'}  }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minHeight: {xs: '72px', sm:'94px'} }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ color: 'white', fontSize: {xs:'16px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'left', lineHeight: 1.1}}>
                        {props.bet.picks.length}-PICK {props.bet.free ? "FREE" : props.bet.flex ? "PROTECTED" : "POWER"} PLAY
                    </Typography>
                    <Typography sx={{ color: 'white', fontSize: {xs:'15px', sm:'18px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'left' }}>
                        ${parseFloat(props.bet.betAmount.$numberDecimal).toFixed(2)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'end' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: {xs:'6px', sm:'8px'}, borderRadius: '8px', bgcolor: status === 1 ? 'rgb(28, 197, 0)' : '#858585', minWidth: {xs: '107px', sm:'132px'}, width: 'fit-content', maxHeight: {xs:'32px', sm: 'none'}, boxSizing: 'border-box' }}>
                        <Typography sx={{ color: '#0D0D0D', fontSize: {xs:'13px', sm:'16px'}, fontFamily: 'Plus Jakarta Sans', fontWeight: 700, }}>
                            {status === 0 ?
                                "IN PROGRESS"
                            :
                                status === 2 && props.bet.flex ?
                                    "PROTECTED"
                                :
                                    status === 2 && !props.bet.flex ?
                                        "REFUNDED"
                                    :
                                        status === 1 ?
                                            "WIN!"
                                        :
                                            "LOSS"
                            }
                        </Typography>
                    </Box>
                    <Box sx={{ minHeight: '36px', flexDirection: {xs:'row', sm:'column'}, display: 'flex', }}>
                        {props.type === "PAST" ?
                            <>
                                <Typography sx={{ color: 'white', fontSize: {xs:'19px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end'}}>
                                    {props.type === "PAST" && status === 1 ?
                                        `+$${parseFloat(props.bet.totalWinAmount.$numberDecimal).toFixed(2)}`
                                    :
                                        null
                                    }
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: {xs:'12px', sm:'15px'}, color: 'rgba(255, 255, 255, 0.5)', fontFamily: 'Poppins', fontWeight: 400, display: {xs: 'block', sm: 'none' }, alignContent: 'center', mb: '5px', ml: '5px' }}>
                                    {props.type === "PAST" && !props.bet.free && status === 1 ?
                                        `[${(parseFloat(props.bet.totalWinAmount.$numberDecimal) / parseFloat(props.bet.betAmount.$numberDecimal)).toFixed(2)}x]`
                                    :
                                        null
                                    }
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: {xs:'13px', sm:'15px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'end', mt: '-6px', display: {xs: 'none', sm: 'block' } }}>
                                    {props.type === "PAST" && !props.bet.free && status === 1 ?
                                        `${(parseFloat(props.bet.totalWinAmount.$numberDecimal) / parseFloat(props.bet.betAmount.$numberDecimal)).toFixed(2)}x`
                                    :
                                        null
                                    }
                                </Typography>
                            </>
                        :
                            null
                        }

                        {props.type === "OPEN" ?
                            <>
                                <Typography sx={{ color: 'white', fontSize: {xs:'19px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end', whiteSpace: 'nowrap'}}>
                                    {props.type === "OPEN" && !props.bet.free && status === 0 ?
                                        `for $${(parseFloat(props.bet.betAmount.$numberDecimal) * multiplier).toFixed(2)}`
                                    :
                                        null
                                    }
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: {xs:'19px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end', whiteSpace: 'nowrap'}}>
                                    {props.type === "OPEN" && props.bet.free && status === 0 ?
                                        `for $20.00`
                                    :
                                        null
                                    }
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: {xs:'12px', sm:'15px'}, color: 'rgba(255, 255, 255, 0.5)', fontFamily: 'Poppins', fontWeight: 400, display: {xs: 'block', sm: 'none' }, alignContent: 'center', mb: '5px', ml: '5px' }}>
                                    {props.type === "OPEN" && !props.bet.free && status === 0 ?
                                        `[${multiplier.toFixed(2)}x]`
                                    :
                                        null
                                    }
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: {xs:'13px', sm:'15px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'end', mt: '-6px', display: {xs: 'none', sm: 'block' } }}>
                                    {props.type === "OPEN" && !props.bet.free && status === 0 ?
                                        `${multiplier.toFixed(2)}x`
                                    :
                                        null
                                    }
                                </Typography>
                            </>
                        :
                            null
                        }

                        {props.type === "FREE" ?
                            <>
                                <Typography sx={{ color: 'white', fontSize: {xs:'19px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end'}}>
                                    {props.type === "FREE" && props.bet.free && status === 0 ?
                                        `for $20.00`
                                    :
                                        null
                                    }
                                </Typography>
                                <Typography sx={{ color: 'white', fontSize: {xs:'18px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end'}}>
                                    {props.type === "FREE" && props.bet.free && status === 1 ?
                                        `+$20.00`
                                    :
                                        null
                                    }
                                </Typography>
                            </>
                        :
                            null
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {isLoading ?
                    expanded ?
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'left', alignItems: 'center' }}>
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Plus Jakarta Sans', fontWeight: 500 }} > 
                                    PICKS
                                    <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                </Typography>
                            </Box>
                            <EntrySummarySkeleton />
                        </>
                    :
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column'  }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'left', alignItems: 'center', width: '100%' }}>
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Plus Jakarta Sans', fontWeight: 500 }} > 
                                    PICKS
                                    {expanded ? 
                                        <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                    : 
                                        <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', height: '36px', flexDirection: 'row', justifyContent: 'space-between', mt: '4px', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: {xs: '32px', sm: '36px'}, mb: {xs: '-24px', sm: '-24px'} }}>
                                    <h1 style={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 800, fontSize: '18px', color: 'rgba(255, 255, 255, 0.1)' }} >AGENTPICKS</h1>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', height: {xs: '18px', sm: '24px'}, mb: {xs: '-24px', sm: '-24px'} }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', md:'14px'}, color: 'rgba(255, 255, 255, 0.3)', lineHeight: 1.2 }}>{convertToDate(props.time).slice(0, -5).toUpperCase()}</Typography>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', md:'14px'}, color: 'rgba(255, 255, 255, 0.3)', lineHeight: 1.2 }}>{convertToTime(props.time)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                :
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'left', alignItems: 'center' }}>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Plus Jakarta Sans', fontWeight: 500 }} > 
                                PICKS
                                {expanded ? 
                                    <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                : 
                                    <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                                }
                            </Typography>
                        </Box>
                        {picks && expanded && picks.map((pickObj) => (
                            <EntrySummaryCard key={pickObj._id + props.bet._id} pickObj={pickObj} status={status} />
                        ))}
                        <Box sx={{ display: 'flex', width: '100%', height: '36px', flexDirection: 'row', justifyContent: 'space-between', mt: '4px', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: {xs: '32px', sm: '36px'}, mb: {xs: '-24px', sm: '-24px'} }}>
                                <h1 style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '18px', color: 'rgba(255, 255, 255, 0.1)' }} >AGENTPICKS</h1>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', height: {xs: '18px', sm: '24px'}, mb: {xs: '-24px', sm: '-24px'} }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', md:'14px'}, color: 'rgba(255, 255, 255, 0.3)', lineHeight: 1.2 }}>{convertToDate(props.time).slice(0, -5).toUpperCase()}</Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', md:'14px'}, color: 'rgba(255, 255, 255, 0.3)', lineHeight: 1.2 }}>{convertToTime(props.time)}</Typography>
                            </Box>
                        </Box>
                    </>
                }
            </Box>

        </Box>
    )
}

export default EntrySummary
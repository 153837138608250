import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import BetInput from "./BetInput";
import CartCard from "./CartCard";
import BetOutput from "./BetOutput";

import { getCartInfo, submitPicks } from '../APIs';

import { TbActivityHeartbeat } from "react-icons/tb";
import { CgChevronDoubleDown } from "react-icons/cg";
import { MdKeyboardBackspace } from "react-icons/md";
import { FaSquareCheck } from "react-icons/fa6";

import { supabase } from '../auth/client';

import BarLoader from "react-spinners/BarLoader";

function MobilePicksCart(props) {

    const [walletBalance, setWalletBalance] = useState(0);
    const [promoBalance, setPromoBalance] = useState(0);
    const [freeEntryBalance, setFreeEntryBalance] = useState(0);
    const [entryInputValue, setEntryInputValue] = useState("");
    const [freeEntry, setFreeEntry] = useState(false);
    const [multiplier1, setMultiplier1] = useState(0);
    const [multiplier2, setMultiplier2] = useState(0);
    const [reduced, setReduced] = useState([0, 0])
    const [boost, setBoost] = useState(0);
    const [hasCC, setHasCC] = useState(false);
    const [member, setMember] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [flex, setFlex] = useState(false);

    const [first, setFirst] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const isValidBetAmount = (betAmount, pickCount) => {

        // Try to convert betAmount to float
        let bet = parseFloat(betAmount)
    
        // Check if betAmount is a valid number
        if (isNaN(bet)) {
            props.setErrorMsg({type: "amount", msg: "Entry amount must be a valid number"})
            return false
        }

        // Check if betAmount is below minimum (1) and not free
        if (bet < 1 && bet > 0) {
            props.setErrorMsg({type: "amount", msg: "The minimum entry amount is $1, except for free entries"})
            return false
        }

        // Check if betAmount is 0
        if (bet === 0) {
            if (!freeEntry) {
                props.setErrorMsg({type: "amount", msg: "The minimum entry amount is $1, except for free entries"})
                return false
            }
        }
    
        if (pickCount < 4 && bet > 200) {
            props.setErrorMsg({type: "amount", msg: `The maximum entry amount is $200 for ${pickCount}-pick entries`})
            return false
        }
    
        // Check if betAmount has no more than 2 decimal places
        let decimalPlaces = (betAmount.split('.')[1] || []).length;
        if (decimalPlaces > 2) {
            props.setErrorMsg({type: "amount", msg: "Entry amount must be 2 decimal places or less"})
            return false
        }
    
        // If all checks pass, return true
        return true
    }

    const handleSubmit = async () => {

        const simplePicks = props.selectedPicks.map((pickObj) => (
            {
                "boardID": pickObj._id,
                "bet": pickObj.bet
            }
        ))

        // check betAmount
        if (!isValidBetAmount(entryInputValue, simplePicks.length)) {
            return
        }

        if (!props.selectedPicks || props.selectedPicks.length < 2) {
            props.setErrorMsg({type: "picks", msg: "Minimum of 2 picks required"})
            return
        } else if (props.selectedPicks.length > 6) {
            props.setErrorMsg({type: "picks", msg: "Maximum of 6 picks allowed"})
            return
        }

        // TODO: check promoCode

        const userData = {
            picks: simplePicks,
            freeEntry: freeEntry,
            promoCode: promoCode,
            betAmount: freeEntry ? "0" : entryInputValue,
            flex: flex,
            token: props.session.access_token
        }

        const data = await submitPicks(userData)

        if (data.status === 401) {
            props.setSession(null)
            setWalletBalance(0)
            setPromoBalance(0)
            setFreeEntryBalance(0)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (data.status !== 200) {
            if (data.errorObj) {
                props.setErrorMsg(data.errorObj)
            } 
            return
        }
        navigate('/myentries')
        return
    }

    const fetchBalance = async () => {
        if (first === null) {
            setIsLoading(true)
            setFirst(true)
        }
        const response = await getCartInfo({token: props.session.access_token})
        
        if (response.status === 401) {
            props.setSession(null)
            setWalletBalance(0)
            setPromoBalance(0)
            setFreeEntryBalance(0)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setWalletBalance(parseFloat(response.info.walletBalance.$numberDecimal))
        setPromoBalance(parseFloat(response.info.promoBalance.$numberDecimal))
        setFreeEntryBalance(response.info.freeEntryBalance)
        setBoost(response.info.boost)
        setHasCC(response.info.hasCC)
        setMember(response.info.member)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        let newMultiplier1 = 0
        let newMultiplier2 = 0
    
        if (props.session) {
            fetchBalance()
        }

        // used for reductions
        let tempReduced = 0.00
        let overs = 0
        let unders = 0
        // DEFINITION: match means series here
        let matches = new Set()
        let maps = new Set()
        // tracks number of overs per SERIES (SERIES = 'team-opp': overs count)
        let oversPerSeries = {}
        // tracks number of unders per SERIES (SERIES = 'team-opp': unders count)
        let undersPerSeries = {}
        // tracks number of overs per MAP (ignores 1-3 and 1-5) (map = 'team-opp-map')
        let oversPerMap = {}
        // tracks number of unders per MAP (ignores 1-3 and 1-5) (map = 'team-opp-map')
        let undersPerMap = {}

        if (props.selectedPicks.length < 6 && freeEntry) {
            setFreeEntry(false)
        }
        if (props.selectedPicks.length < 4 && flex) {
            setFlex(false)
        }
        if (props.selectedPicks.length > 4 && !flex) {
            setFlex(true)
        }

        for (const pick of props.selectedPicks) {
            const series = [pick.team, pick.opp].sort().join('-');
            const match = `${series}-${pick.game}`
            matches.add(match)

            const map = pick.maps
            const mapKey = `${match}-${map}`
            maps.add(mapKey)

            if (pick.bet === 'UNDER') {
                unders += 1
                if (!undersPerSeries[match]) {
                    undersPerSeries[match] = 0
                }
                undersPerSeries[match]++
                if (!undersPerMap[mapKey]) {
                    undersPerMap[mapKey] = 0
                }
                undersPerMap[mapKey]++
            } else if (pick.bet === 'OVER') {
                overs += 1
                if (!oversPerSeries[match]) {
                    oversPerSeries[match] = 0
                }
                oversPerSeries[match]++
                if (!oversPerMap[mapKey]) {
                    oversPerMap[mapKey] = 0
                }
                oversPerMap[mapKey]++
            }
        }

        const numOfSeries = matches.size

        // MAP SPECIFIC REDUCTIONS
        // IF 2 PICKS TOTAL AND 2/2 PICKS OVER/OVER or UNDER/UNDER for 1 MAP (predicting banger or snoozefest map) = -15%
        if (numOfSeries === 1 && overs + unders === 2) {
            if (Object.values(oversPerMap).some(value => value === 2)) {
                tempReduced = Math.max(0.15, tempReduced)
            }
            if (Object.values(undersPerMap).some(value => value === 2)) {
                tempReduced = Math.max(0.15, tempReduced)
            }
        }

        // SERIES SPECIFIC REDUCTIONS
        // 1 SERIES
        if (numOfSeries === 1) {
            if (overs === 0) {
                // 2/2 PICKS OVER/OVER or UNDER/UNDER = -10%
                if (unders === 2) {
                    tempReduced = Math.max(0.1, tempReduced)
                }
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER = -15%
                else if (unders === 3) {
                    tempReduced = Math.max(0.15, tempReduced)
                } 
            } else if (unders === 0) {
                // 2/2 PICKS OVER/OVER or UNDER/UNDER = -10%
                if (overs === 2) {
                    tempReduced = Math.max(0.1, tempReduced)
                }
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER = -15%
                else if (overs === 3) {
                    tempReduced = Math.max(0.15, tempReduced)
                } 
            }
        }
        // 2 SERIES
        else if (numOfSeries === 2) {
            if (overs + unders === 3) {
                // 2/2 PICKS OVER/OVER or UNDER/UNDER for 1 SERIES = -8%
                if (Object.values(oversPerSeries).some(value => value >= 2)) {
                    tempReduced = Math.max(0.08, tempReduced)
                }
                if (Object.values(undersPerSeries).some(value => value >= 2)) {
                    tempReduced = Math.max(0.08, tempReduced)
                }
            } 
            else if (overs + unders === 4) {
                // 4/4 PICKS OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER = -15%
                if (overs === 4 || unders === 4) {
                    tempReduced = Math.max(0.15, tempReduced)
                }
                // 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES1 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES2 = -24%
                if (Object.values(oversPerSeries).some(value => value === 2) && Object.values(undersPerSeries).some(value => value === 2)) {
                    tempReduced = Math.max(0.24, tempReduced)
                }
                // 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES1 (and OVER/UNDER opposites for SERIES2) = -8%
                for (const [key, value] of Object.entries(oversPerSeries)) {
                    if (value === 2 && (!undersPerSeries[key] || undersPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.08, tempReduced);
                    }
                }
                for (const [key, value] of Object.entries(undersPerSeries)) {
                    if (value === 2 && (!oversPerSeries[key] || oversPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.08, tempReduced);
                    }
                }
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for 1 SERIES = -14%
                if (Object.values(oversPerSeries).some(value => value >= 3)) {
                    tempReduced = Math.max(0.14, tempReduced)
                }
                if (Object.values(undersPerSeries).some(value => value >= 3)) {
                    tempReduced = Math.max(0.14, tempReduced)
                }

            } 
            else if (overs + unders === 5) {
                // 5/5 PICKS OVER/OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER/UNDER = -15%
                if (overs === 5 || unders === 5) {
                    tempReduced = Math.max(0.15, tempReduced)
                }
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for SERIES1 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES2 = -15%
                if (Object.values(oversPerSeries).some(value => value === 3) && Object.values(undersPerSeries).some(value => value === 2)) {
                    tempReduced = Math.max(0.15, tempReduced)
                }
                if (Object.values(oversPerSeries).some(value => value === 2) && Object.values(undersPerSeries).some(value => value === 3)) {
                    tempReduced = Math.max(0.15, tempReduced)
                }
                // 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES1 (and ANYTHING for SERIES2) = -8%
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for SERIES1 (and OVER/UNDER opposites for SERIES2) = -12%
                for (const [key, value] of Object.entries(oversPerSeries)) {
                    if (value === 2 && (!undersPerSeries[key] || undersPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.08, tempReduced);
                    }
                    if (value === 3 && (!undersPerSeries[key] || undersPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.12, tempReduced);
                    }
                }
                for (const [key, value] of Object.entries(undersPerSeries)) {
                    if (value === 2 && (!oversPerSeries[key] || oversPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.08, tempReduced);
                    }
                    if (value === 3 && (!oversPerSeries[key] || oversPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.12, tempReduced);
                    }
                }

            }
            else if (overs + unders === 6) {
                // 6/6 PICKS OVER/OVER/OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER/UNDER/UNDER = -20%
                if (overs === 6 || unders === 6) {
                    tempReduced = Math.max(0.35, tempReduced)
                }
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for SERIES1 AND 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for SERIES2 = -35%
                if (Object.values(oversPerSeries).some(value => value === 3) && Object.values(undersPerSeries).some(value => value === 3)) {
                    tempReduced = Math.max(0.35, tempReduced)
                }
                // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for SERIES1 (and ANYTHING for SERIES2) = -15%
                for (const [key, value] of Object.entries(oversPerSeries)) {
                    if (value === 3 && (!undersPerSeries[key] || undersPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.15, tempReduced);
                    }
                }
                for (const [key, value] of Object.entries(undersPerSeries)) {
                    if (value === 3 && (!oversPerSeries[key] || oversPerSeries[key] === 0)) {
                        tempReduced = Math.max(0.15, tempReduced);
                    }
                }
            }
        }
        // 3+ SERIES
        else {
            // 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER = -8%
            if (overs + unders === 3) {
                if (unders === 3 || overs === 3) {
                    tempReduced = Math.max(0.08, tempReduced)
                } 
            }
            // 4/4 PICKS OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER = -12%
            else if (overs + unders === 4) {
                if (unders === 4 || overs === 4) {
                    tempReduced = Math.max(0.12, tempReduced)
                } 
            }
            // 5/5 PICKS OVER/OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER/UNDER = -14%
            else if (overs + unders === 5) {
                if (unders === 5 || overs === 5) {
                    tempReduced = Math.max(0.14, tempReduced)
                } 
                const count3 = Object.values(oversPerSeries).filter(value => value >= 3).length
                const count4 = Object.values(undersPerSeries).filter(value => value >= 3).length
                if (count3 === 1 || count4 === 1) {
                    // IF SERIES1 OVER/OVER/OVER or UNDER/UNDER/UNDER and ANYTHING for rest = -20%
                    tempReduced = Math.max(0.2, tempReduced)
                }
                const count5 = Object.values(oversPerSeries).filter(value => value === 2).length
                const count6 = Object.values(undersPerSeries).filter(value => value === 2).length
                if (count5 + count6 >= 1) {
                    // IF SERIES1 OVER/OVER or UNDER/UNDER and ANYTHING for rest = -10%
                    tempReduced = Math.max(0.1, tempReduced)
                }
                // IF 3 SERIES AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES1 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES2 (and ANYTHING for SERIES3) = -16%
                if (numOfSeries === 3) {  
                    const count1 = Object.values(oversPerSeries).filter(value => value === 2).length
                    const count2 = Object.values(undersPerSeries).filter(value => value === 2).length
                    if (count1 + count2 === 2) {
                        tempReduced = Math.max(0.16, tempReduced)
                    }
                }
            }
            // 6/6 PICKS OVER/OVER/OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER/UNDER/UNDER = -15%
            else if (overs + unders === 6) {
                if (unders === 6 || overs === 6) {
                    tempReduced = Math.max(0.15, tempReduced)
                } 
                const count3 = Object.values(oversPerSeries).filter(value => value >= 3).length
                const count4 = Object.values(undersPerSeries).filter(value => value >= 3).length
                if (count3 + count4 >= 1) {
                    // IF SERIES1 OVER/OVER/OVER or UNDER/UNDER/UNDER and ANYTHING for rest = -20%
                    tempReduced = Math.max(0.2, tempReduced)
                }
                const count5 = Object.values(oversPerSeries).filter(value => value === 2).length
                const count6 = Object.values(undersPerSeries).filter(value => value === 2).length
                if (count5 + count6 >= 1) {
                    // IF SERIES1 OVER/OVER or UNDER/UNDER and ANYTHING for rest = -10%
                    tempReduced = Math.max(0.1, tempReduced)
                }
                if (numOfSeries === 3) {  
                    const count1 = Object.values(oversPerSeries).filter(value => value >= 2).length
                    const count2 = Object.values(undersPerSeries).filter(value => value >= 2).length
                    if (count1 + count2 === 2) {
                        // IF 3 SERIES AND 3/3 PICKS OVER/OVER/OVER or UNDER/UNDER/UNDER for SERIES1 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES2 (and ANYTHING for SERIES3) = -30%
                        if (Object.values(oversPerSeries).some(value => value === 3) || Object.values(undersPerSeries).some(value => value === 3)) {
                            tempReduced = Math.max(0.30, tempReduced)
                        } 
                        // IF 3 SERIES AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES1 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES2 (and ANYTHING for SERIES3) = -24%
                        else {
                            tempReduced = Math.max(0.24, tempReduced)
                        }
                    }
                    // IF 3 SERIES AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES1 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES2 AND 2/2 PICKS OVER/OVER or UNDER/UNDER for SERIES3  = -35%
                    else if (count1 + count2 === 3) {
                        tempReduced = Math.max(0.35, tempReduced)
                    }
                }
            }
        }

        // multiplier calculation for non flex picks
        if (props.selectedPicks.length < 2) {
            newMultiplier1 = 0.00
        } else if (props.selectedPicks.length === 2) {
            newMultiplier1 = (3.00 * (1.00 - tempReduced)) + ((3.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 3) {
            newMultiplier1 = (5.00 * (1.00 - tempReduced)) + ((5.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 4) {
            newMultiplier1 = (10.00 * (1.00 - tempReduced)) + ((10.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 5) {
            newMultiplier1 = (17.00 * (1.00 - tempReduced)) + ((17.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 6) {
            newMultiplier1 = (28.00 * (1.00 - tempReduced)) + ((28.00 * (1.00 - tempReduced)) * boost)
        }
        setMultiplier1(Math.round(newMultiplier1 * 100) / 100);
    
        // multiplier calculation for flex picks
        if (props.selectedPicks.length === 4) {
            newMultiplier2 = (7.00 * (1.00 - tempReduced)) + ((7.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 5) {
            newMultiplier2 = (9.50 * (1.00 - tempReduced)) + ((9.50 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 6) {
            newMultiplier2 = (23.50 * (1.00 - tempReduced)) + ((23.50 * (1.00 - tempReduced)) * boost)
        }
        setMultiplier2(Math.round(newMultiplier2 * 100) / 100);
    
        setReduced(tempReduced)

        setIsLoading(false)
    
    }, [props.selectedPicks, props.session]);

    const isScreenSmall = () => window.innerWidth < 900;


    return (
        <Box sx={{ 
            height: '100%',
            boxsizing: 'border-box', 
            display: props.cartOpen ? {xs: 'flex', md: 'none'} : 'none',
            flexDirection: 'column', 
            justifyContent: 'center',
            width: '100%', 
        }}>
            {isLoading ?
                <Box sx={{ overflowY: 'auto', height: '100%', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >
                    <BarLoader
                        color="#A80000"
                        loading="true"
                        height={6}
                        width={220}
                        data-testid="loader"
                    />
                </Box>
            :
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', width: '100%', minHeight: '44px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', top: '54px', left: 0, bgcolor: '#0B1215', zIndex: 90, cursor: 'pointer' }}>
                        <Box onClick={() => props.setCartOpen(!props.cartOpen)} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '4px', '&:hover': { color: 'rgba(168, 0, 0, 1)' }, color: 'rgba(168, 0, 0, 0.8)' }}>
                            <MdKeyboardBackspace size={32} />
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px' }} >
                                BOARD
                            </Typography>
                        </Box>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'whitesmoke', mr: '4px' }} >
                            {props.selectedPicks.length} PLAYER{props.selectedPicks.length > 1 || props.selectedPicks.length === 0 ? 'S' : ''} SELECTED
                        </Typography>
                    </Box>
                    <Box sx={{ height: '100%', boxSizing: 'border-box' }} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px', px: '10px', boxSizing: 'border-box' }}>
                            {props.selectedPicks.map((pickObj, index) => (
                                <CartCard key={index} pickObj={pickObj} selectedPicks={props.selectedPicks} setSelectedPicks={props.setSelectedPicks} />
                            ))}
                        </Box>
                        {props.selectedPicks.length < 2 ? 
                            props.selectedPicks.length === 0 ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: props.selectedPicks.length === 0 ? '100%' : '60%', alignItems: 'center' }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '20px', color: 'white', mb: '16px' }} >
                                        NO PLAYERS SELECTED
                                    </Typography>
                                    <img src='/people.svg' height={'90px'} ></img>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '12px', color: 'white', mt: '16px' }} >
                                        You can complete your entry
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                        when 2+ players are selected.
                                    </Typography>
                                </Box>
                            :
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: props.selectedPicks.length === 0 ? '100%' : '60%', alignItems: 'center' }}>
                                    <img src='/people.svg' height={'80px'} ></img>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '12px', color: 'white', mt: '16px' }} >
                                        You can complete your entry
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                        when 2+ players are selected.
                                    </Typography>
                                </Box>
                        :
                            <Box sx={{ display: freeEntry ? 'none' : 'flex', justifyContent: 'center', boxSizing: 'border-box', width: '100%', flexDirection: 'row', mt: '24px', px: '10px', gap: {xs:'8px', sm:'12px'}, mb: '12px' }}>
                                {props.selectedPicks.length <= 4 ? 
                                    <Box onClick={ () => setFlex(false) } sx={{ 
                                        display: 'flex', justifyContent: 'start', boxSizing: 'border-box', position: 'relative',
                                        width: '100%', flexDirection: 'column', bgcolor: !flex ? 'whitesmoke' : 'rgba(255, 255, 255, 0.05)', 
                                        border: !flex ? '1px solid whitesmoke' : '1px solid rgba(255, 255, 255, 0.1)',
                                        borderRadius: '8px', minHeight: '160px', p: {xs:'8px', sm:'12px'}, pb: '16px', gap: {xs:'6px', sm:'8px'},
                                        maxWidth: props.selectedPicks.length === 4 ? 'none' : 'calc(50% - 6px)', cursor: 'pointer'
                                    }}>
                                        {!flex ? 
                                            <Box sx={{ position: 'absolute', top: {xs:'8px', sm:'12px'}, right: {xs:'8px', sm:'12px'} }}> 
                                                <FaSquareCheck color="rgba(168, 0, 0, 0.8)" size={18} />
                                            </Box> 
                                        : 
                                            null
                                        }
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'14px', sm:'16px'}, color: !flex ? 'black' : 'white', textAlign: 'left' }} >
                                            Power Play
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'12px', sm:'13px'}, color: !flex ? 'black' : 'white', textAlign: 'left', lineHeight: 1.2  }} >
                                            You must hit {props.selectedPicks.length} out of {props.selectedPicks.length} picks to win.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', width: '100%', flexDirection: 'row', mt: '12px' }}>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'12px', sm:'14px'}, color: !flex ? 'black' : 'white', textAlign: 'left', alignContent: 'center' }} >
                                                {props.selectedPicks.length} correct
                                            </Typography>
                                            <Box sx={{ bgcolor: 'rgba(168, 0, 0, 0.8)', borderRadius: '4px', py: '2px', px: '6px' }}>
                                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'11px', sm:'13px'}, color: 'white', textAlign: 'left' }} >
                                                    {multiplier1 > 0 && multiplier1.toFixed(2)}X
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                :
                                    null
                                }
                                {props.selectedPicks.length >= 4 ? 
                                    <Box onClick={ () => setFlex(true) } sx={{ 
                                        display: 'flex', justifyContent: 'start', boxSizing: 'border-box', position: 'relative',
                                        width: '100%', flexDirection: 'column', bgcolor: flex ? 'whitesmoke' : 'rgba(255, 255, 255, 0.05)', 
                                        border: flex ? '1px solid whitesmoke' : '1px solid rgba(255, 255, 255, 0.1)',
                                        borderRadius: '8px', minHeight: '160px', p: {xs:'8px', sm:'12px'}, pb: '16px', gap: {xs:'6px', sm:'8px'},
                                        maxWidth: props.selectedPicks.length === 4 ? 'none' : 'calc(50% - 6px)', cursor: 'pointer'
                                    }}>
                                        {flex ? 
                                            <Box sx={{ position: 'absolute', top: {xs:'8px', sm:'12px'}, right: {xs:'8px', sm:'12px'} }}> 
                                                <FaSquareCheck color="rgba(168, 0, 0, 0.8)" size={18} />
                                            </Box> 
                                        : 
                                            null
                                        }
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'14px', sm:'16px'}, color: flex ? 'black' : 'white', textAlign: 'left' }} >
                                            Protected Play
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'12px', sm:'13px'}, color: flex ? 'black' : 'white', textAlign: 'left', lineHeight: 1.2  }} >
                                            You must hit {props.selectedPicks.length} out of {props.selectedPicks.length} picks to win. Or hit {props.selectedPicks.length === 6 ? 4 : 3}{props.selectedPicks.length === 4 ? '' : '+'} picks to win 1x as promo credit.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', width: '100%', flexDirection: 'row', mt: '12px' }}>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'12px', sm:'14px'}, color: flex ? 'black' : 'white', textAlign: 'left', alignContent: 'center' }} >
                                                {props.selectedPicks.length} correct
                                            </Typography>
                                            <Box sx={{ bgcolor: 'rgba(168, 0, 0, 0.8)', borderRadius: '4px', py: '2px', px: '6px' }}>
                                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'11px', sm:'13px'}, color: 'white', textAlign: 'left' }} >
                                                    {multiplier2 > 0 && multiplier2.toFixed(2)}X
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', width: '100%', flexDirection: 'row', mt: '4px' }}>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'12px', sm:'14px'}, color: flex ? 'black' : 'white', textAlign: 'left', alignContent: 'center' }} >
                                                {props.selectedPicks.length - 1} correct
                                            </Typography>
                                            <Box sx={{ bgcolor: 'rgba(168, 0, 0, 0.8)', borderRadius: '4px', py: '2px', px: '6px' }}>
                                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'11px', sm:'13px'}, color: 'white', textAlign: 'left' }} >
                                                    1.00X
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {
                                            props.selectedPicks.length >= 5 ?
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', width: '100%', flexDirection: 'row', mt: '4px' }}>
                                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs:'12px', sm:'14px'}, color: flex ? 'black' : 'white', textAlign: 'left', alignContent: 'center' }} >
                                                        {props.selectedPicks.length - 2} correct
                                                    </Typography>
                                                    <Box sx={{ bgcolor: 'rgba(168, 0, 0, 0.8)', borderRadius: '4px', py: '2px', px: '6px' }}>
                                                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'11px', sm:'13px'}, color: 'white', textAlign: 'left' }} >
                                                            1.00X
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            :
                                                null
                                        }
                                    </Box>
                                :
                                    null
                                }
                            </Box>
                        }
                    </Box>
                    <Box sx={{ p: {xs: '4px 12px 20px 12px', sm: '4px 24px 20px 24px'} }}>
                        {props.selectedPicks.length >= 2 ?
                            <Box sx={{ display: 'flex', alignContent: 'flex-start', mb: '3px', alignItems: 'center', my: '8px' }}>
                                <TbActivityHeartbeat color="rgba(168, 0, 0, 0.8)" size={24} />
                                {!flex ?
                                    <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'white', ml:'4px' }} >
                                        {multiplier1 > 0 && multiplier1.toFixed(2)}
                                    </Typography>
                                :
                                    <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'white', ml:'4px' }} >
                                        {multiplier2 > 0 && multiplier2.toFixed(2)}
                                    </Typography>
                                }
                                {reduced > 0 ?
                                    <Tooltip 
                                        title={
                                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '11px', my: '-2px', color: 'whitesmoke' }}>
                                                {(reduced * 100).toFixed(0)}% reduction for this pick combo
                                            </Typography>
                                        } 
                                        placement="right" arrow 
                                        open={props.cartOpen && isScreenSmall()}
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [-2, -10],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <CgChevronDoubleDown color="gray" size={20} style={{ marginBottom: '2px' }}/> 
                                        </Box>
                                    </Tooltip>
                                :
                                    null
                                }
                                { freeEntryBalance > 0 && props.selectedPicks.length === 6 ? 
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2px', ml: '10px' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'rgba(255, 255, 255, 0.5)', mr: '6px' }} >
                                            or
                                        </Typography>
                                        <Checkbox 
                                            onChange={() => {
                                                setFlex(false)
                                                setFreeEntry(!freeEntry) 
                                                setEntryInputValue("0") 
                                            }}
                                            checked={freeEntry}
                                            sx={{ 
                                                p: 0, 
                                                color: 'rgba(168, 0, 0, 0.8)',
                                                '&.Mui-checked': {
                                                    color: 'rgba(168, 0, 0, 0.8)',
                                                },
                                            }} 
                                        />
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'white' }} >
                                            Use 1 Free Entry
                                        </Typography>
                                    </Box>
                                :
                                    null
                                }
                            </Box>
                        :
                            null
                        }
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                            <BetInput entryInputValue={entryInputValue} setEntryInputValue={setEntryInputValue} freeEntry={freeEntry} />
                            <BetOutput entryInputValue={freeEntry ? 20 : flex ? (entryInputValue * multiplier2) : (entryInputValue * multiplier1)} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: {xs:'fit-content', sm:'115px'}, ml: {xs:'6px', sm:'8px'} }}>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                    Balance: ${walletBalance > 0 ? walletBalance.toFixed(2) : 0.00.toFixed(2)}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                    Promo: ${promoBalance > 0 ? promoBalance.toFixed(2) : 0.00.toFixed(2)}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'rgba(255, 255, 255, 0.5)' }} >
                                    Free Entries: {freeEntryBalance > 0 ? freeEntryBalance : 0}
                                </Typography>
                            </Box>
                        </Box>
                        {
                            props.validLocation === undefined && props.session ?
                                <Button variant="contained" onClick={() => {props.session ? props.setErrorMsg({type: "location", msg: "To play AgentPicks, allow 'Location' in browser settings"}) : navigate('/login')}} sx={{ 
                                    bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                    color: 'white',
                                    width: '100%',
                                    height: '52px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(168, 0, 0, 1)',
                                        boxShadow: '1px 1px 1px black !important',
                                    },
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    mt: '16px'
                                }}>
                                    LOCATION REQUIRED
                                </Button>
                            :
                                !props.validLocation && props.validLocation !== undefined && props.session ?
                                    <Button variant="contained" onClick={() => {props.session ? props.setErrorMsg({type: "location", msg: `To play AgentPicks, you must live in a valid state. ${props.detectedState} is not a valid state.`}) : navigate('/login')}} sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                        color: 'white',
                                        width: '100%',
                                        height: '52px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        mt: '16px'
                                    }}>
                                        INVALID LOCATION
                                    </Button>
                                :
                                    <Button variant="contained" onClick={() => {props.session ? handleSubmit() : navigate('/login')}} sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                        color: 'white',
                                        width: '100%',
                                        height: '52px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        mt: '16px'
                                    }}>
                                        {props.session ? 'Place Entry' : 'Login'}
                                    </Button>
                        }
                        {!isLoading && !member && props.session ?
                            <Box sx={{ mt: '16px', display: 'flex', justifyContent: 'center' }} onClick={() => navigate('/settings')} >
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'white', cursor: 'pointer', textDecorationLine: 'underline' }} >
                                    Become a prestige member to boost winnings by 5%
                                </Typography>
                            </Box>
                        :
                            null
                        }
                    </Box>
                </Box>
            }
        </Box>
    );
}
    
export default MobilePicksCart;
import { Box, InputAdornment, Input } from '@mui/material';

// for withdraw requests
const AmountInput = (props) => {

    const handleChange = (e) => {
        let value = e.target.value
        // Remove any non-numeric characters except for a single decimal point
        value = value.replace(/[^\d.]/g, '')
        // Remove leading zeros
        value = value.replace(/^0+/, '')
        // Remove any negative symbols
        value = value.replace(/-/g, '')
        // Check if the value contains more than 2 decimal places
        if ((value.split('.')[1] || '').length > 2) {
            // Truncate the value to only keep 2 decimal places
            value = props.amount
        }

        // Check if the value is greater than 9999 and doesn't contain a decimal point
        if (parseFloat(value) > 9999 && !value.includes('.')) {
            // Set the value to the previous value
            value = props.amount
        }

        // Update the state with the parsed value
        props.setAmount(value)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                border: 1,
                borderColor: 'rgba(255, 255, 255, 0.2)',
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                textAlign: 'left',
                height: '42px',
                padding: {xs:'6px 12px 6px 12px', sm:'4px 16px 8px 16px'}, 
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                boxSizing: 'border-box'
            }}
        >
            <Input
                type="number"
                startAdornment={
                    <InputAdornment position="start" sx={{ "& .MuiTypography-root": { color: 'white'} }}>
                        $
                    </InputAdornment>
                }
                onChange={handleChange}
                placeholder='Enter Withdrawal Amount'
                value={props.amount}
                disableUnderline 
                fullWidth 
                sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 500,
                    fontSize: {xs:'14px', sm:'16px'},
                    mt: {xs:'0px', sm:'4px'},
                    color: 'white',
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "white",
                    },
                }}
            />
        </Box>
    );
};

export default AmountInput;
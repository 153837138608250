import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

function MyEntriesSkeleton(props) {
  return (
    <>
      <Box sx={{ flexDirection: 'column', width: {xs: '98%', lg:'100%'}, 
        boxsizing: 'border-box', mt: {xs: '8px', md: 0}, 
        border: {xs: '1px solid rgba(255, 255, 255, 0.1)', 
        md: 'none'}, bgcolor: {xs:'#171E21', md: 'transparent'},
        pt: {xs:'16px', md: 0 }, borderRadius: '8px'
      }} >
        <Box sx={{ display: 'flex', justifyContent: {xs:'space-around', sm:'center'}, flexDirection: 'row', gap: {xs:'8px', sm:'16px'}, mx: {xs: '12px', sm:0} }} >
          <Button onClick={() => {
              props.setType("OPEN")
            }}
            variant="contained" 
            sx={{ 
              width: {xs:'auto', sm:'160px'},
              height: {xs:'50px', sm:'56px'}, 
              minWidth: {xs:'80px', sm:'160px'},
              minHeight: {xs:'50px', sm:'56px'},
              p: {xs:'6px 8px', sm:'6px 16px'}, 
              fontWeight: 'bold', 
              fontFamily: 'Poppins',
              lineHeight: 1,
              display: 'flex', 
              fontSize: {xs:'13px', sm:'16px'},
              color: props.type === 'OPEN' ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
              borderRadius: {xs:'8px', md:'20px'},
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              boxShadow: props.type === 'OPEN' ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
              border: props.type === 'OPEN' ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
              ':hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                  color: 'rgba(168, 0, 0, 1)',
                  border: '1px solid rgba(168, 0, 0, 0.5)'
              },
            }}
          >
            Open Entries
          </Button>
          <Button onClick={() => {
              props.setType("PAST")
            }}  
            variant="contained" 
            sx={{ 
              width: {xs:'auto', sm:'160px'},
              height: {xs:'50px', sm:'56px'}, 
              minWidth: {xs:'80px', sm:'160px'},
              minHeight: {xs:'50px', sm:'56px'},
              p: {xs:'6px 8px', sm:'6px 16px'}, 
              fontWeight: 'bold', 
              fontFamily: 'Poppins',
              lineHeight: 1,
              display: 'flex', 
              fontSize: {xs:'13px', sm:'16px'},
              color: props.type === 'PAST' ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
              borderRadius: {xs:'8px', md:'20px'},
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              boxShadow: props.type === 'PAST' ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
              border: props.type === 'PAST' ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
              ':hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                  color: 'rgba(168, 0, 0, 1)',
                  border: '1px solid rgba(168, 0, 0, 0.5)'
              },
            }}
          >
            Past Entries
          </Button>
          <Button onClick={() => {
              props.setType("FREE")
            }} 
            variant="contained" 
            sx={{ 
              width: {xs:'auto', sm:'160px'},
              height: {xs:'50px', sm:'56px'}, 
              minWidth: {xs:'80px', sm:'160px'},
              minHeight: {xs:'50px', sm:'56px'},
              p: {xs:'6px 8px', sm:'6px 16px'}, 
              fontWeight: 'bold', 
              fontFamily: 'Poppins',
              lineHeight: 1,
              display: 'flex', 
              fontSize: {xs:'13px', sm:'16px'},
              color: props.type === 'FREE' ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
              borderRadius: {xs:'8px', md:'20px'},
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              boxShadow: props.type === 'FREE' ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
              border: props.type === 'FREE' ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
              ':hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                  color: 'rgba(168, 0, 0, 1)',
                  border: '1px solid rgba(168, 0, 0, 0.5)'
              },
            }}
          >
            Free Entries
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: {xs:'16px', md:'32px'}, gap: {xs:'16px', md:'32px'}, px: {xs:'12px', md:'0px'} }}>
          <Skeleton variant="rounded" sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              width: '100%', height: '209px',
          }} />
          <Skeleton variant="rounded" sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              width: '100%', height: '209px',
          }} />
          <Skeleton variant="rounded" sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              width: '100%', height: '209px',
          }} />
          <Skeleton variant="rounded" sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              width: '100%', height: '209px',
          }} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: {xs: '98%', md:'340px', lg:'365px'}, boxSizing: 'border-box', minWidth: {xs: '265px', md:'340px', lg:'365px'} }}>
        <Skeleton variant="rounded" sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.1)', 
            height: {xs:'305px', sm:'310px', md:'392px'}, 
            boxsizing: 'border-box',
        }} />
      </Box>
    </>
  )
}
export default MyEntriesSkeleton
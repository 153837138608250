import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';

import { supabase } from '../auth/client';

import { getEntries, getUserInfo } from '../APIs';

import { IoMdSettings } from "react-icons/io";
import EntrySummary from "../components/EntrySummary";
import CreditCardDialog from "../components/CreditCardDialog";
import MyEntriesSkeleton from "../skeletons/MyEntriesSkeleton";
import WithdrawDialog from "../components/WithdrawDialog";

function EntriesPage(props) {

  const navigate = useNavigate()

  const [open2, setOpen2] = useState(false)

  const [entries, setEntries] = useState(null)
  const [type, setType] = useState("OPEN")
  const [walletBalance, setWalletBalance] = useState(0)
  const [entriesWon, setEntriesWon] = useState(0)
  const [amountWon, setAmountWon] = useState(0)
  const [joinDate, setJoinDate] = useState("")
  const [name, setName] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const ENTRIES_PER_PAGE = 5

  // Get entries for selected page
  const entriesSelected = entries?.[type.toLowerCase()] || []
  // Calculate total pages
  const totalPages = entriesSelected.length >= 1 ? Math.ceil(entriesSelected.length / ENTRIES_PER_PAGE) : 0
  // Get current page entries
  const currentEntries = entriesSelected.slice(
    (currentPage - 1) * ENTRIES_PER_PAGE,
    currentPage * ENTRIES_PER_PAGE
  )

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const [errorMsg, setErrorMsg] = useState({})
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchInfo = async () => {
    setIsLoading(true)
    const response = await getUserInfo({token: props.session.access_token})
    
    if (response.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (response.status !== 200) {
      setIsLoading(false)
      return
    }

    setWalletBalance(parseFloat(response.info.walletBalance.$numberDecimal))
    props.setPromoBalance(parseFloat(response.info.promoBalance.$numberDecimal))
    setEntriesWon(response.info.entriesWon)
    setAmountWon(parseFloat(response.info.amountWon.$numberDecimal))
    setJoinDate(response.info.joinDate)
    setName(response.info.name)

    await fetchEntries()

    setIsLoading(false)

    return
  }

  // gets a user's entries (from users DB)
  const fetchEntries = async () => {
    const response = await getEntries({token: props.session.access_token})
    
    if (response.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (response.status !== 200) {
      setIsLoading(false)
      return
    }

    setEntries(response.entries)

    return
  }

  const handleDepositClick = async () => {
    if (name && props.isVerified) {
      props.setOpen(props.isVerified)
    } else {
      setErrorMsg({type: 'unverified', msg: 'ID verification required for deposit'})
    }
    return
  }

  const handleWithdrawClick = async () => {
    if (name && props.isVerified) {
      setOpen2(props.isVerified)
    } else {
      setErrorMsg({type: 'unverified', msg: 'ID verification required for withdraw'})
    }
    return
  }
  

  useEffect(() => {
    if (props.session) {
      fetchInfo()
    }
  }, [props.session])

  useEffect(() => {
    if (props.session) {
      fetchEntries()
    }
  }, [props.session, type])

  useEffect(() => {
    if (success) {
      fetchInfo()
      const timer = setTimeout(() => {
        setSuccess(0)
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [success])

  useEffect(() => {
    if (Object.keys(errorMsg).length > 0 && errorMsg.type === "unverified") {
      const timer = setTimeout(() => {
        setErrorMsg({})
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [errorMsg])


  return (
    <Box 
      sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          minHeight: '100vh', 
          width: {
              xs: '100%', 
              lg: 'calc(100% - 300px)', 
              xl: 'calc(100% - 320px)'
          },
          mt: {xs:'78px', md:'104px'},
          boxSizing: 'border-box',
          pb: '130px',
          pr: {
            xs: '0px',
            xl: '320px'
          },
      }}
    >
      {isLoading ?
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: {xs: 'column-reverse', md: 'row'}, gap: '16px', alignItems: {xs:'center', md:'flex-start'}, boxSizing: 'border-box', width: '100%' }}>
          <MyEntriesSkeleton type={type} setType={setType} />
        </Box>
      :
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: {xs: 'column-reverse', md: 'row'}, gap: '16px', alignItems: {xs:'center', md:'flex-start'}, boxSizing: 'border-box', width: '100%' }}>
          {success === 1 ? 
            <Alert sx={{ 
              position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
              zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
              border: '2px solid #2e7d32', color: 'white', 
              fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
              alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
            }} severity="success" >
              Deposit Successful
            </Alert> 
          : null }
          {success === -1 ? 
            <Alert sx={{ 
              position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
              zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
              border: '2px solid #d32f2f', color: 'white', 
              fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
              alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
            }} severity="error" >
              Deposit Unsuccessful: Payment Declined
            </Alert> 
          : null }
          {errorMsg && errorMsg.msg && errorMsg.type === 'unverified' ? 
            <Alert sx={{ 
                position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
                zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                border: '2px solid #d32f2f', color: 'white', 
                fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
            }} severity="error" >
                {errorMsg?.msg}
            </Alert> 
          : null }
          {props.open && name && props.isVerified ? <CreditCardDialog open={props.open} setOpen={props.setOpen} session={props.session} setSession={props.setSession} setSuccess={setSuccess} /> : null}
          {open2 && name && props.isVerified ? <WithdrawDialog open={open2} setOpen={setOpen2} session={props.session} setSession={props.setSession} /> : null}
          <Box sx={{ flexDirection: 'column', width: {xs: '98%', lg:'100%'}, 
            boxsizing: 'border-box', mt: {xs: '8px', md: 0}, 
            border: {xs: '1px solid rgba(255, 255, 255, 0.1)', 
            md: 'none'}, bgcolor: {xs:'#171E21', md: 'transparent'},
            py: {xs:'16px', md: 0 }, borderRadius: '8px', minWidth: '310px'
          }} >
            <Box sx={{ display: 'flex', justifyContent: {xs:'space-around', sm:'center'}, flexDirection: 'row', gap: {xs:'8px', sm:'16px'}, mx: {xs: '12px', sm:0} }} >
              <Button onClick={() => {
                  setType("OPEN")
                  setCurrentPage(1)
                }}
                variant="contained" 
                sx={{ 
                  width: {xs:'auto', sm:'160px'},
                  height: {xs:'50px', sm:'56px'}, 
                  minWidth: {xs:'80px', sm:'160px'},
                  minHeight: {xs:'50px', sm:'56px'},
                  p: {xs:'6px 8px', sm:'6px 16px'}, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins',
                  lineHeight: 1,
                  display: 'flex', 
                  fontSize: {xs:'13px', sm:'16px'},
                  color: type === 'OPEN' ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                  borderRadius: {xs:'8px', md:'20px'},
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: type === 'OPEN' ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
                  border: type === 'OPEN' ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
                  ':hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.05)',
                      boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                      color: 'rgba(168, 0, 0, 1)',
                      border: '1px solid rgba(168, 0, 0, 0.5)'
                  },
                }}
              >
                Open Entries
              </Button>
              <Button onClick={() => {
                  setType("PAST")
                  setCurrentPage(1)
                }}  
                variant="contained" 
                sx={{ 
                  width: {xs:'auto', sm:'160px'},
                  height: {xs:'50px', sm:'56px'}, 
                  minWidth: {xs:'80px', sm:'160px'},
                  minHeight: {xs:'50px', sm:'56px'},
                  p: {xs:'6px 8px', sm:'6px 16px'}, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins',
                  lineHeight: 1,
                  display: 'flex', 
                  fontSize: {xs:'13px', sm:'16px'},
                  color: type === 'PAST' ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                  borderRadius: {xs:'8px', md:'20px'},
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: type === 'PAST' ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
                  border: type === 'PAST' ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
                  ':hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.05)',
                      boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                      color: 'rgba(168, 0, 0, 1)',
                      border: '1px solid rgba(168, 0, 0, 0.5)'
                  },
                }}
              >
                Past Entries
              </Button>
              <Button onClick={() => {
                  setType("FREE")
                  setCurrentPage(1)
                }} 
                variant="contained" 
                sx={{ 
                  width: {xs:'auto', sm:'160px'},
                  height: {xs:'50px', sm:'56px'}, 
                  minWidth: {xs:'80px', sm:'160px'},
                  minHeight: {xs:'50px', sm:'56px'},
                  p: {xs:'6px 8px', sm:'6px 16px'}, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins',
                  lineHeight: 1,
                  display: 'flex', 
                  fontSize: {xs:'13px', sm:'16px'},
                  color: type === 'FREE' ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                  borderRadius: {xs:'8px', md:'20px'},
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: type === 'FREE' ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
                  border: type === 'FREE' ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
                  ':hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.05)',
                      boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                      color: 'rgba(168, 0, 0, 1)',
                      border: '1px solid rgba(168, 0, 0, 0.5)'
                  },
                }}
              >
                Free Entries
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: {xs:'16px', md:'32px'}, gap: {xs:'16px', md:'32px'}, px: {xs:'12px', md:'0px'} }}>
              {
                entriesSelected.length > 0 ?
                  currentEntries.map((bet, index) => (
                    <EntrySummary key={bet._id} time={bet.time} index={index} bet={bet} session={props.session} setSession={props.setSession} type={type} currentPage={currentPage} />
                  ))
                :
                  <Box sx={{ minHeight: '300px', height: '300px', width: '100%', pt: '64px' }}>
                    <img src="/noentries.svg" height="164px" alt="No Entries Found" />
                    <Typography sx={{ fontFamily: 'Poppins', fontWeight: 700, color: 'white', fontSize: {xs:'22px', md:'28px'}, mt: '24px' }} >NO ENTRIES FOUND</Typography>
                  </Box>
              }
            </Box>
            {totalPages > 1 ?
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '20px' }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      fontFamily: 'Poppins',
                      fontSize: {xs:'16px', sm:'18px'},
                      color: 'white', // Normal state
                      minWidth: {xs:'24px', sm:'32px'}

                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                      fontFamily: 'Poppins',
                      fontSize: {xs:'16px', sm:'18px'},
                      color: '#A80000', // Selected state
                      minWidth: {xs:'24px', sm:'32px'}
                    },
                    '& .MuiPaginationItem-root:hover': {
                      fontFamily: 'Poppins',
                      fontSize: {xs:'16px', sm:'18px'},
                      color: '#A80000', // Hover state
                      minWidth: {xs:'24px', sm:'32px'}
                    },
                  }}
                />
              </Box>
            :
              null
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: {xs: '98%', md:'340px', lg:'365px'}, 
            boxSizing: 'border-box', minWidth: {xs: '265px', md:'340px', lg:'365px'}, 
          }}>
            <Box sx={{  
              border: '1px solid rgba(255, 255, 255, 0.1)',
              bgcolor: '#171E21',
              boxsizing: 'border-box', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              borderRadius: '8px', 
              p: '16px',
              position: 'relative'
            }}>
              <Box onClick={() => navigate('/settings')}  sx={{ position: 'absolute', top: '20px', right: '16px', 
                cursor: 'pointer', height: '48px', width: '48px', display: 'flex', justifyContent: 'center', 
                alignItems: 'center', bgcolor: '#23292C', borderRadius: '8px', color: '#808080', 
                border: '1px solid rgba(255, 255, 255, 0.1)', boxSizing: 'border-box',
                '&:hover': {color: 'lightgray', bgcolor: '#303030', border: '1px solid rgba(255, 255, 255, 0.2)'} 
                }}
              >
                <IoMdSettings size={28}/>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px', boxsizing: 'border-box', mt: '4px' }}>
                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: {xs: '18px', sm:'20px'}, color: name ? 'white' : '#A80000', lineHeight: 1.1, textAlign: 'left', maxWidth: {xs:'225px', sm:'270px'} }} >
                  {name && props.isVerified ? 
                    name
                  : 
                    "ID Verification Required"
                  }
                </Typography>
                {name && props.isVerified ? 
                  <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs: '14px', sm: '16px' }, color: 'white', lineHeight: 1.1 }} >
                    Joined {joinDate}
                  </Typography>
                :
                  <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs: '14px', sm: '16px' }, color: 'white', lineHeight: 1.1 }} >
                    Verify your ID {<span onClick={() => navigate('/settings')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</span>} to place entries
                  </Typography>
                }
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', boxsizing: 'border-box', gap: {xs:'12px' , md:'16px'}, mt: {xs:'32px' , md:'48px'}}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#23292C', borderRadius: '8px', width: '100%', height: {xs:'58px' , md:'80px'}, border: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'14px' , md:'16px'}, color: 'rgba(255, 255, 255, 0.5)' }} >
                    ENTRIES WON
                  </Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'18px' , md:'22px'}, color: 'white' }} >
                    {entriesWon}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#23292C', borderRadius: '8px', width: '100%', height: {xs:'58px' , md:'80px'}, border: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'14px' , md:'16px'}, color: 'rgba(255, 255, 255, 0.5)' }} >
                    AMOUNT WON
                  </Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'18px' , md:'22px'}, color: 'white' }} >
                    ${amountWon.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', boxsizing: 'border-box', gap: {xs:'12px' , md:'16px'}, mt: {xs:'12px' , md:'16px'}}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#23292C', borderRadius: '8px', width: '100%', height: {xs:'58px' , md:'80px'}, border: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'14px' , md:'16px'}, color: 'rgba(255, 255, 255, 0.5)' }} >
                    BALANCE
                  </Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'18px' , md:'22px'}, color: 'white' }} >
                    ${walletBalance.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#23292C', borderRadius: '8px', width: '100%', height: {xs:'58px' , md:'80px'}, border: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'14px' , md:'16px'}, color: 'rgba(255, 255, 255, 0.5)' }} >
                    PROMO
                  </Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'18px' , md:'22px'}, color: 'white' }} >
                    ${props.promoBalance.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', boxsizing: 'border-box', gap: '16px', mt: '16px'}}>
                <Button onClick={() => handleWithdrawClick()} sx={{
                  display: 'flex',
                  border: 1,
                  borderColor: '#A80000',
                  bgcolor: 'rgba(168, 0, 0, 0.1)',
                  borderRadius: '8px 0px 0px 8px',
                  ':hover': {
                    boxShadow: 'inset 0px 0px 14px #A80000',
                    border: '1px solid #A80000',
                    bgcolor: 'rgba(168, 0, 0, 0.1)',
                  },
                  height: {xs:'42px' , md:'60px'},
                  width: '100%',
                  justifyContent: 'center', 
                  alignItems: 'center',
                  p: 0,
                  m: 0,
                  textTransform: 'none',
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins',
                  lineHeight: 1,
                  fontSize: {xs:'13px', sm:'16px'},
                  color: 'white'
                }} >
                  WITHDRAW
                </Button>
                <Button onClick={() => handleDepositClick()} sx={{
                  display: 'flex',
                  border: 1,
                  borderColor: '#1CC500',
                  bgcolor: 'rgba(28, 197, 0, 0.1)',
                  borderRadius: '0px 8px 8px 0px',
                  ':hover': {
                    boxShadow: 'inset 0px 0px 14px #1CC500',
                    border: '1px solid #1CC500',
                    bgcolor: 'rgba(28, 197, 0, 0.1)',
                  },
                  height: {xs:'42px' , md:'60px'},
                  width: '100%',
                  justifyContent: 'center', 
                  alignItems: 'center',
                  p: 0,
                  m: 0,
                  textTransform: 'none',
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins',
                  lineHeight: 1,
                  fontSize: {xs:'13px', sm:'16px'},
                  color: 'white'
                }} >
                  DEPOSIT
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    </Box>
  )
}

export default EntriesPage
import { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";

import EarningsGraph from './EarningsGraph';

function FriendPromotionCard(props) {

    const [expanded1, setExpanded1] = useState(false)
    const [copied, setCopied] = useState(false)

    const [readyForAnimations, setReadyForAnimations] = useState(false)

    const handleCopy = () => {
        setCopied(true)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyForAnimations(true)
        }, 500)
    }, [])

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied(false)
            }, 2000)

            return () => clearTimeout(timer);
        }
    }, [copied])
      

    return (
        <animated.div style={useSpring({
            height: expanded1 ? '720px' : '300px',
            from: { height: '300px' },
            immediate: !readyForAnimations
        })}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'start', 
                boxSizing: 'border-box', 
                bgcolor: '#171E21',
                width: {xs:'340px', lg:'350px'}, 
                borderRadius: '8px',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                border: '1px solid rgba(255, 255, 255, 0.1)',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', boxSizing: 'border-box', width: '100%', px: '16px', height: '184px', py: '28px', minHeight: '184px' }} >
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                        REFER A FRIEND
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1, textAlign: 'left' }}>
                            Invite friends to AgentPicks and have them use your referral code on sign up
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1, textAlign: 'left' }}>
                            Get a 1% bonus for yourself every time your friends win
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 900, fontSize: '13px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', lineHeight: 1.1 }}>
                            •
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1 }}>
                            Total number of referrals: 
                        </Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '16px', color: 'rgba(168, 0, 0, 0.8)', alignSelf: 'start', ml: '6px', lineHeight: 1 }}>
                            {props.referrals}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', boxSizing: 'border-box', gap: '12px', height: '56px', minHeight: '56px' }}>
                    {/*<Button variant="contained" onClick={() => window.open("https://esportsagent.gg", "_blank")} sx={{ 
                        bgcolor: '#252525', 
                        color: 'white',
                        width: '120px',
                        height: '44px',
                        '&:hover': {
                            backgroundColor: '#303030',
                        },
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 500,
                        fontSize: '12px',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        lineHeight: 1.1,
                        px: '4px'
                    }}>
                        Learn More
                    </Button> */}
                    <Button variant="contained" onClick={() => {
                            navigator.clipboard.writeText(props.referralCode)
                            handleCopy()
                        }} sx={{ 
                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                        color: 'white',
                        width: '120px',
                        height: '44px',
                        '&:hover': {
                            backgroundColor: 'rgba(168, 0, 0, 1)',
                        },
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 700,
                        fontSize: '13px',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        lineHeight: 1.1,
                        px: '4px'
                    }}>
                        {copied ? <FaCheck size={'18px'} /> : null }
                        <span style={{ marginLeft: copied ? '6px' : 0 }}>{copied ? "Copied" : "Copy Code" }</span>
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', boxSizing: 'border-box', width: '100%', px: '16px', height: '440px', py: '12px', minHeight: '440px' }} >
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start', mb: '12px' }}>
                        Refer a Friend Bonus Earnings
                    </Typography>
                    <EarningsGraph data={props.monthlyREarnings} />
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '14px', color: 'white', alignSelf: 'center', mt: '12px' }}>
                        Last 6 Month Earnings: ${(props.monthlyREarnings.reduce((sum, item) => sum + item.amt, 0)).toFixed(2)}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '14px', color: 'white', alignSelf: 'center' }}>
                        Total Earnings: ${props.referralEarnings.toFixed(2)}
                    </Typography>
                </Box>
                <Box onClick={() => {setExpanded1(!expanded1)}} 
                    sx={{ display: 'flex', 
                        flexDirection: 'row', boxSizing: 'border-box', 
                        justifyContent: 'center', cursor: 'pointer', 
                        position: 'absolute', bottom: 0, width: '100%', 
                        height: '42px', bgcolor: '#23292C', zIndex: 2, 
                        alignItems: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.1)', 
                        '&:hover': {backgroundColor: '#303030'} 
                    }}
                >
                    {expanded1 ?
                        <>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                                HIDE EARNINGS
                            <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                            </Typography>
                        </>
                    :
                        <>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                                SHOW EARNINGS
                            <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                            </Typography>
                        </>
                    }
                </Box>
            </Box>
        </animated.div>
    )
}

export default FriendPromotionCard

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

import { TbActivityHeartbeat } from "react-icons/tb";

function CartBtn(props) {

    return (
        <Button
            variant='outlined'
            size='large'
            onClick={() => {
                props.setCartOpen(!props.cartOpen)
            }}
            sx={{
                width: '60px',
                minWidth: '52px',
                height: '44px', 
                fontWeight: 'regular', 
                fontFamily: 'Poppins',
                display: 'flex', 
                p: 0,
                color: 'rgba(168, 0, 0, 0.8)',
                borderRadius: '8px',
                bgcolor: '#23292C',
                boxShadow: props.cartOpen ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                border: props.cartOpen ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
                ':hover': {
                    bgcolor: '#23292C',
                    boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                    color: 'rgba(168, 0, 0, 1)',
                    border: '1px solid rgba(168, 0, 0, 0.5)'
                }
            }}
        >
            <TbActivityHeartbeat size={28} />
            <Box sx={{ position: 'absolute', top: {xs: -4, md: -8}, right: {xs: -4, md: -8}, bgcolor: 'rgba(168, 0, 0, 1)', borderRadius: 100, display: 'flex', width: {xs:'18px', md:'20px'}, height: {xs:'18px', md:'20px'}, boxSizing: 'border-box', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ color: 'black', lineHeight: 1.2, fontSize: '12px', fontWeight: 600, fontFamily: 'Plus Jakarta Sans' }}>
                    {props.pickCount}
                </Typography>
            </Box>
        </Button>
    );
}
    
export default CartBtn;
import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { adminCreateCC } from '../APIs';

import { supabase } from '../auth/client';


function AdminCCDialog(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [code, setCode] = useState("")
    const [active, setActive] = useState(true)
    const [matching, setMatching] = useState("0")

    const [errorMsg, setErrorMsg] = useState({})


    function isValidString(input, minLength, maxLength) {
        return typeof input === 'string' && input.length >= minLength && input.length <= maxLength;
    }
    
    function isValidBoolean(input) {
        return typeof input === 'boolean';
    }

    // Check if value is empty, null, undefined, or NaN
    function isValidValue(value) {
        return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
    }

    function isValidNumber(value) {
        const numberValue = Number(value);
        return Number.isInteger(numberValue) && numberValue >= 0;
    }

    function isValidCreatorCode(creatorCode) {
        // Regular expression that matches strings of length 2-9 containing only alphanumeric characters
        const regex = /^[a-zA-Z0-9]{2,9}$/;
        return regex.test(creatorCode);
    }
    
    function verifyData(userData) {
    
        if (!isValidValue(userData.code) || !isValidString(userData.code, 1, 9) || !isValidCreatorCode(userData.code)) {
            setErrorMsg({type: "code", msg: "Invalid creator code format. Must be alphanumeric and 2-9 characters"});
            return false;
        }
        if (!isValidValue(userData.active) || !isValidBoolean(userData.active)) {
            setErrorMsg({type: "active", msg: "Invalid active value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.matching) || !isValidNumber(userData.matching)) {
            setErrorMsg({type: "matching", msg: "Invalid matched deposit limit. Must be a whole number >= 0"});
            return false;
        }
        // If all checks pass, clear any previous error message
        setErrorMsg({});
        return true;
    }

    const handleSubmit = async () => {

        // check inputs

        const userData = {
            code: code,
            active: active,
            matching: matching,
            token: props.adminSession.access_token
        }

        if (!verifyData(userData)) {
            return
        } 

        const data = await adminCreateCC(userData)

        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } if (data.status !== 200) {
            const errorMsg = data?.errorObj?.msg ? data.errorObj.msg : 'Server error';
            setErrorMsg({ type: 'other', msg: errorMsg });
            return
        }

        props.setUpdate(!props.update)
        handleClose()
    }
    
    const handleClose = () => {
        props.setOpen(false)
        setErrorMsg({})
        setCode("")
        setActive(true)
        setMatching("0")
    }

    const handleCodeChange = (event) => {
        setCode(event.target.value)
    }

    const handleActiveChange = (event) => {
        setActive(event.target.value)
    }

    const handleMatchingChange = (event) => {
        setMatching(event.target.value)
    }

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0 && errorMsg.type === "other") {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)

          return () => clearTimeout(timer);
        }
    }, [errorMsg])

    return (
        <ThemeProvider theme={darkTheme}>
            {errorMsg && errorMsg.msg && errorMsg.type === 'other' ? 
                <Alert sx={{ 
                    position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
                    zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <Dialog
                open={props.open}
                onClose={handleClose}
            >
                <DialogTitle>Create New Player Bet</DialogTitle>
                <DialogContent sx={{ minWidth: '600px', boxSizing: 'border-box' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="code"
                        name="code"
                        label="Code"
                        fullWidth
                        variant="outlined"
                        value={code}
                        onChange={handleCodeChange}
                        error={errorMsg.type === "code" ? true : false}
                        helperText={errorMsg.type === "code" ? errorMsg.msg : false}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mt: '10px' }}>
                        <InputLabel htmlFor="active">Active</InputLabel>
                        <Select
                            value={active}
                            onChange={handleActiveChange}
                            input={<OutlinedInput label="" />}
                            inputProps={{
                                name: 'active',
                                id: 'active',
                            }}
                        >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                        </Select>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mt: '10px' }}>
                        <InputLabel htmlFor="matching">Deposit Matching</InputLabel>
                        <TextField
                            sx={{ mb: '-1px' }}
                            margin="dense"
                            id="matching"
                            name="matching"
                            label="Matched Deposit Limit (per user)"
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={matching}
                            onChange={handleMatchingChange}
                            error={errorMsg.type === "matching" ? true : false}
                            helperText={errorMsg.type === "matching" ? errorMsg.msg : false}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Create</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default AdminCCDialog
import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { MdExpandLess, MdExpandMore } from "react-icons/md";

function BoardCardBet(props) {

    const [higherSelected, setHigherSelected] = useState(false)
    const [lowerSelected, setLowerSelected] = useState(false)

    const handlePick = (choice) => {
        if (props.selectedPicks.length === 0) {
            props.setCartOpen(true)
        }
        // if clicking an already selected button or modifying an already selected pick
        if (higherSelected || lowerSelected || props.selectedPicks.some(pick => pick._id === props.bet._id)) {
            // if clicking an already selected button
            if ((higherSelected && choice === "OVER") || (lowerSelected && choice === "UNDER")) {
                const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.bet._id)
                props.setSelectedPicks(updatedPicks)
            } 
            // if changing from over to under or under to over
            else {
                const updatedPicks = props.selectedPicks.map(pick => {
                    if (pick._id === props.bet._id) {
                        return {
                            ...pick,
                            bet: choice,
                        }
                    } else {
                        return pick
                    }
                })
                props.setSelectedPicks(updatedPicks)
            }
        } 
        // if selecting pick for a the first time
        else {
            // if trying to add more than 6 picks
            if (props.selectedPicks.length === 6) {
                props.setErrorMsg({type: "picks", msg: "Maximum of 6 picks allowed"})
            }
            // if less than 6 picks already
            else {
                props.setSelectedPicks([...props.selectedPicks, {
                    _id: props.bet._id,  
                    name: props.bet.name,
                    game: props.bet.game,
                    team: props.bet.team,
                    opp: props.bet.opp,
                    pro: props.bet.pro,
                    img: props.bet.img,
                    matchDateTimeEST: props.bet.matchDateTimeEST,
                    maps: props.bet.maps,
                    predicted: props.bet.predicted,
                    special: props.bet?.special ? props.bet?.special : "",
                    stat: props.bet.stat,
                    bet: choice,
                }])
            }
        }
    }

    // for handling picks on mobile
    const handlePick2 = (choice) => {

        // if clicking an already selected button or modifying an already selected pick
        if (higherSelected || lowerSelected || props.selectedPicks.some(pick => pick._id === props.bet._id)) {
            // if clicking an already selected button
            if ((higherSelected && choice === "OVER") || (lowerSelected && choice === "UNDER")) {
                const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.bet._id)
                props.setSelectedPicks(updatedPicks)
            } 
            // if changing from over to under or under to over
            else {
                const updatedPicks = props.selectedPicks.map(pick => {
                    if (pick._id === props.bet._id) {
                        return {
                            ...pick,
                            bet: choice,
                        }
                    } else {
                        return pick
                    }
                })
                props.setSelectedPicks(updatedPicks)
            }
        } 
        // if selecting pick for a the first time
        else {
            // if trying to add more than 6 picks
            if (props.selectedPicks.length === 6) {
                props.setErrorMsg({type: "picks", msg: "Maximum of 6 picks allowed"})
            }
            // if less than 6 picks already
            else {
                props.setSelectedPicks([...props.selectedPicks, {
                    _id: props.bet._id,  
                    name: props.bet.name,
                    game: props.bet.game,
                    team: props.bet.team,
                    opp: props.bet.opp,
                    pro: props.bet.pro,
                    img: props.bet.img,
                    matchDateTimeEST: props.bet.matchDateTimeEST,
                    maps: props.bet.maps,
                    predicted: props.bet.predicted,
                    special: props.bet?.special ? props.bet?.special : "",
                    stat: props.bet.stat,
                    bet: choice,
                }])
            }
        }
    }

    const checkIfSelected = () => {
        if (!props.selectedPicks) {
            return false
        }
        setHigherSelected(props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER"))
        setLowerSelected(props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER"))
    }

    useEffect(() => {
        checkIfSelected()
    }, [props.selectedPicks])

    const formatting = ["HEADSHOTS", "FIRST BLOODS", "FIRST DEATHS"]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'space-between', pr: '8px', pl:'10px' }}>
            <Box sx={{ display: 'flex', boxSizing: 'border-box', flexDirection: {xs:'column', md: 'row'}, alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', boxSizing: 'border-box', flexDirection: 'row', alignItems: 'flex-end', width: {xs:'100%', md:'auto'}, height: '100%', pb: {xs:0, md:'3px'} }}>
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs: '20px', md: '22px'}, color: props.bet?.special === "DISCOUNTED" ? 'gold' : 'white', lineHeight: 1.1 }} >{props.bet.predicted}</Typography>
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 300, fontSize: {xs: '13px', md: formatting.includes(props.bet.stat) ? '13px' : '14px'}, color: 'white', ml: '5px', lineHeight: 1.2 }} >{props.bet.stat}</Typography>
                </Box>
                <Box sx={{ display: 'flex', boxSizing: 'border-box', flexDirection: 'row', alignItems: 'flex-end', width: {xs:'100%', md:'auto'}, height: '100%', pb: {xs:0, md:'3px'} }}>
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 300, fontSize: {xs: '13px', md: formatting.includes(props.bet.stat) ? '13px' : '14px'}, color: 'white', ml:{xs:0, md:'5px'}, alignSelf: {xs:'start', md:'auto'}, lineHeight: 1.2, }} >MAP{props.bet.maps.length > 1 ? "S" : ""} {props.bet.maps}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: {xs: 'none', md: 'flex'}, boxSizing: 'border-box', flexDirection: 'row', gap: '4px', width: '132px', justifyContent: 'center' }}>
                <Button 
                    onClick={() => handlePick('OVER')}
                    sx={{ 
                        width: props.bet.pro && props.bet.special !== 'DISCOUNTED' ? '64px' : '100%',
                        minWidth: '64px',
                        height: '38px',
                        borderRadius: '8px',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 600,
                        fontSize: '12px',
                        color: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                        textTransform: 'none',
                        boxShadow: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                        border: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? '1px solid rgba(168, 0, 0, 0.8)' : 'none',
                        ':hover': {
                            boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.5)',
                            color: 'rgba(168, 0, 0, 1)',
                            border: '1px solid rgba(168, 0, 0, 0.8)'
                        },
                        '& .MuiButton-startIcon': {
                            mr: 0,
                        },
                        display: 'flex',
                        justifyContent: 'center',
                    }} 
                    startIcon={<MdExpandLess size={18} />}
                >
                    MORE
                </Button>
                <Button 
                    disabled={!props.bet.pro || props.bet.special === 'DISCOUNTED'}
                    onClick={() => handlePick('UNDER')}
                    sx={{
                        visibility: props.bet.pro && props.bet.special !== 'DISCOUNTED' ? 'visible' : 'hidden',
                        width: '64px',
                        minWidth: '64px',
                        height: '38px',
                        borderRadius: '8px',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 600,
                        fontSize: '12px',
                        color: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                        textTransform: 'none',
                        boxShadow: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                        border: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? '1px solid rgba(168, 0, 0, 0.8)' : 'none',
                        ':hover': {
                            boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.7)',
                            color: 'rgba(168, 0, 0, 1)',
                            border: '1px solid rgba(168, 0, 0, 0.8)'
                        },
                        '& .MuiButton-startIcon': {
                            mr: 0,
                        },
                        display: props.bet.pro && props.bet.special !== 'DISCOUNTED' ? 'flex' : 'none',
                        justifyContent: 'center',
                    }} 
                    startIcon={<MdExpandMore size={18} />}
                >
                    LESS
                </Button>
            </Box>
            {/* START MOBILE VERSION */}
            <Box sx={{ display: {xs: 'flex', md: 'none'}, boxSizing: 'border-box', flexDirection: 'row', gap: '4px', width: '128px', justifyContent: 'center' }}>
                <Button 
                    onClick={() => handlePick2('OVER')}
                    sx={{ 
                        width: props.bet.pro && props.bet.special !== 'DISCOUNTED' ? '62px' : '100%',
                        minWidth: '62px',
                        height: '38px',
                        borderRadius: '8px',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 600,
                        fontSize: '11px',
                        color: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                        textTransform: 'none',
                        boxShadow: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                        border: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? '1px solid rgba(168, 0, 0, 0.8)' : 'none',
                        ':hover': {
                            boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.7)',
                            color: 'rgba(168, 0, 0, 1)',
                            border: '1px solid rgba(168, 0, 0, 0.8)'
                        },
                        '& .MuiButton-startIcon': {
                            mr: 0,
                        },
                        display: 'flex',
                        justifyContent: 'center',
                    }} 
                    startIcon={<MdExpandLess size={18} />}
                >
                    MORE
                </Button>
                <Button 
                    disabled={!props.bet.pro && props.bet.special === 'DISCOUNTED'}
                    onClick={() => handlePick2('UNDER')}
                    sx={{
                        visibility: props.bet.pro && props.bet.special !== 'DISCOUNTED' ? 'visible' : 'hidden',
                        width: '62px',
                        minWidth: '62px',
                        height: '38px',
                        borderRadius: '8px',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 600,
                        fontSize: '11px',
                        color: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                        textTransform: 'none',
                        boxShadow: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                        border: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? '1px solid rgba(168, 0, 0, 0.8)' : 'none',
                        ':hover': {
                            boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.7)',
                            color: 'rgba(168, 0, 0, 1)',
                            border: '1px solid rgba(168, 0, 0, 0.8)'
                        },
                        '& .MuiButton-startIcon': {
                            mr: 0,
                        },
                        display: props.bet.pro && props.bet.special !== 'DISCOUNTED' ? 'flex' : 'none',
                        justifyContent: 'center',
                    }} 
                    startIcon={<MdExpandMore size={18} />}
                >
                    LESS
                </Button>
            </Box>
        </Box>
    )
}

export default BoardCardBet
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { MdAccountCircle } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { FiGift } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";

import { supabase } from '../auth/client';
import NavBtn2 from './NavBtn2';
import { getUserInfoForWithdraw } from '../APIs';

function NavMenu(props) {

    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    const logout = async () => {
        props.setMenuVisible(false)
        props.setSession(null)
        const { error } = await supabase.auth.signOut()
        navigate('/login')
    }


    const navigate = useNavigate()
    let location = useLocation().pathname.substring(1)

    if (location.endsWith('/')) {
        location = location.slice(0, -1);
    }

    const locationFormatter = { '': 'Board', 'board': 'Board', 'myentries': 'My Entries', 'promotions': 'Promotions', 'howtoplay': 'How To Play', 'login': 'Login', 'settings': 'Settings'}

    let curr = locationFormatter[location] ?? ''
    curr = curr.toUpperCase() ?? ''


    const fetchInfo = async () => {
        setIsLoading(true)
        const response = await getUserInfoForWithdraw({token: props.session.access_token})
        
        if (response.status === 401) {
            props.setSession(null)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setFirst(response?.info?.firstName)
        setLast(response?.info?.lastName)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        if (props.session) {
            fetchInfo()
        }
    }, [props.session]);

    useEffect(() => {
        if (props.menuVisible) {
            document.body.style.overflow = 'hidden';  // Disable scrolling
        } else {
            document.body.style.overflow = 'auto';  // Re-enable scrolling
        }
    }, [props.menuVisible]);

    return (
        <Box sx={{ display: { xs: 'flex', lg: 'none' }, boxSizing: 'border-box', 
            justifyContent: 'center', width: '100%', minWidth: '280px', bgcolor: 'red'
        }}>
            <Box sx={{ bgcolor: '#0B1215', overflow: 'auto',
                height: '100%', boxSizing: 'border-box', 
                width: '100%', pt: '20px', pb: '36px', 
                px: '12px', flexDirection: 'column', 
                justifyContent: 'space-between', display: 'flex',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', minHeight: '633px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '24px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '64px', justifyContent: 'space-between', px: '8px' }}>
                            <Box onClick={() => navigate('/')} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', ml: '-4px' }}>
                                <img src='/logo.svg' height={'44px'}/>
                                <h1 style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '28px', color: 'white' }} >AGENTPICKS</h1>
                            </Box>
                            <Box onClick={() => props.setMenuVisible(false)} sx={{ height: '100%', justifyContent: 'center', alignContent: 'center', cursor: 'pointer', display: !props.menuVisible ? 'none' : 'block'  }}>
                                <AiOutlineClose size={36} color='white' />
                            </Box>
                        </Box>
                        {props.session ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '12px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '8px', p: '4px', boxSizing: 'border-box', gap: '8px', width: '100%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '38px', gap: '20px', px: '16px', boxSizing: 'border-box', }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', boxSizing: 'border-box', }}>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '20px', color: '#267800', mb: '2px' }}>$</Typography>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '16px', color: 'white', ml: '3px' }}>{props.walletBalance > 0 ? props.walletBalance.toFixed(2) : '0.00'}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', boxSizing: 'border-box', }}>
                                            <FiGift color='rgba(168, 0, 0, 0.8)' fontSize={21} style={{ marginBottom: '-1px' }}/>
                                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: '16px', color: 'white', ml: '4px' }}>{props.promoBalance > 0 ? props.promoBalance.toFixed(2) : '0.00'}</Typography>
                                        </Box>
                                    </Box>
                                    <Box 
                                        onClick={() => props.handleDepositBtnClick()} 
                                        sx={{ 
                                            display: 'flex', 
                                            cursor: 'pointer', 
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            '&:hover': {
                                                color: 'black',
                                                bgcolor: 'rgba(168, 0, 0, 1)',
                                            },
                                            color: 'black',
                                            width: '30px',
                                            height: '30px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '6px',
                                            boxSizing: 'border-box'
                                        }} 
                                    >
                                        <IoAdd size={24} color='white' />
                                    </Box>
                                </Box>
                            </Box>
                        :
                            null
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '16px', my: '24px' }}>
                            <NavBtn2 page={'BOARD'} curr={curr} to={'/board'} setMenuVisible={props.setMenuVisible} />
                            <NavBtn2 page={'MY ENTRIES'} curr={curr} to={'/myentries'} setMenuVisible={props.setMenuVisible} />
                            <NavBtn2 page={'PROMOTIONS'} curr={curr} to={'/promotions'} setMenuVisible={props.setMenuVisible} />
                            <NavBtn2 page={'SETTINGS'} curr={curr} to={'/settings'} setMenuVisible={props.setMenuVisible} />
                            <NavBtn2 page={'HOW TO PLAY'} curr={curr} to={'/howtoplay'} setMenuVisible={props.setMenuVisible} />
                            <NavBtn2 page={'SUPPORT CENTER'} curr={curr} to={'/promotions'} setMenuVisible={props.setMenuVisible} />
                        </Box>
                    </Box>
                    {props.session ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ 
                                    color: 'rgba(168, 0, 0, 0.8)', 
                                    '&:hover': {
                                        color: 'rgba(168, 0, 0, 1)',
                                    },
                                    width: '40px', 
                                    height: '40px',
                                }}>
                                    <MdAccountCircle size={'40px'} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <Typography sx={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins', lineHeight: 1.1 }}>
                                        {first} {last}
                                    </Typography>
                                    <Typography sx={{ color: 'gray', fontSize: '11px', fontFamily: 'Poppins', lineHeight: 1.1  }}>
                                        {props.session?.user?.email}
                                    </Typography>
                                </Box>
                            </Box>
                            <Button variant="contained" onClick={() => logout()} sx={{ 
                                bgcolor: '#23292C', 
                                color: 'white',
                                width: '100%',
                                maxWidth: '100px',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Logout
                            </Button>
                        </Box>
                    :
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', boxSizing: 'border-box', gap: '16px' }}>
                            <Button variant="contained" onClick={() => {
                                    props.setMenuVisible(false)
                                    navigate('/login')}
                                } sx={{ 
                                bgcolor: '#23292C', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Log In
                            </Button>
                            <Button variant="contained" onClick={() => window.open("https://esportsagent.gg/signup", "_blank")} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Sign Up
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}
export default NavMenu;
import './App.css';

import { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';


import NavBar from './components/Navbar';
import BoardPage from './pages/BoardPage';
import EntriesPage from './pages/EntriesPage';
import PromotionsPage from './pages/PromotionsPage';
import LoginPage from './pages/LoginPage';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { supabase } from './auth/client';
import { useSession } from './context/SessionProvider';
import { useCacheBuster } from 'react-cache-buster';

import SettingsPage from './pages/SettingsPage';
import AdminPage from './pages/AdminPage';
import RedirectPage from './pages/RedirectPage';
import { getIsVerified, isUserInAPDB, register2, sendLocationData, sendLocationData2, setMembershipStatus } from './APIs';
import HowToPlayPage from './pages/HowToPlayPage';
import LocationVerificationDialog from './components/LocationVerificationDialog';
import NavBar2 from './components/Navbar2';

function App() {

  const { session, setSession, linkedLogin, setLinkedLogin } = useSession();

  const [promoBalance, setPromoBalance] = useState(0)
  // used to open payment modal on myentries page (from navbar)
  const [open, setOpen] = useState(false)
  // used to prevent user from opening payment modal
  const [isVerified, setIsVerified] = useState(false)
  const [validLocation, setValidLocation] = useState(undefined)
  const [detectedState, setDetectedState] = useState("?")

  // used to check if someone is in the process of logging in with linked account
  // prevents login form and/or board from flashing during process
  // const [linkedLogin, setLinkedLogin] = useState(false)

  const [errorMsg, setErrorMsg] = useState({})

  const navigate = useNavigate();
  const location = useLocation();
  const isAdminRoute = location.pathname === '/admin';

  const handleLinkedLogin = async (session) => {

    // TODO: check if session?.user?.email in eaDB

    // checks if user (based on email) is in AP DB
    const res = await isUserInAPDB({ email: session?.user?.email })
    if (res.status !== 200) {
      setSession(null)
      setLinkedLogin(false)
      const { error } = await supabase.auth.signOut()
      return
    }
    const APUser = res.exists
    let isVerified2 = APUser ? res.isVerified : false

    // if user is not in AP DB
    if (!APUser) {

      const fullUserData = {
        email: session?.user?.email,
        supabaseID: session?.user?.id,
        token: session?.access_token
      }

      // TODO: prevent people from spamming /register?
      const res2 = await register2(fullUserData)
      if (res2.status !== 200) {
        setSession(null)
        setLinkedLogin(false)
        setErrorMsg(res2?.errorObj)
        const { error } = await supabase.auth.signOut()
        return
      } 
      
    } else {
      const res3 = await setMembershipStatus({token: session?.access_token})
      if (res3.status !== 200) {
        setSession(null)
        setLinkedLogin(false)
        const { error } = await supabase.auth.signOut()
        return
      } 
    }

    setIsVerified(isVerified2)

    if (!isVerified2) {
      navigate('/settings', { state: { from: '/login' } });
      return
    } else {
      navigate('/board')
      return
    }
  }

  // checks verification status and membership status (global contexts)
  const performInitialChecks = async () => {

    // if user had a session
    if (session) {

      // check for login with twitch/discord/twitter etc
      if (linkedLogin) {
        handleLinkedLogin(session)
        return
      }

      // get verification status on refresh
      const response = await getIsVerified({token: session.access_token})
      // get membership status on refresh
      const response2 = await setMembershipStatus({token: session.access_token})

      // if invalid session or banned: logout
      if (response.status !== 200 || response2.status !== 200) {
        setSession(null)
        const { error } = await supabase.auth.signOut()
        if (location.pathname !== '/login' && location.pathname !== '/board' && location.pathname !== '/howtoplay') {
          navigate('/login');
        }
        return
      }

      setIsVerified(response.isVerified)
    } 
    // if user has no valid session
    else {
      setIsVerified(false)
    }
  }

  
  useEffect(() => {
    if (Object.keys(errorMsg).length > 0) {
      const timer = setTimeout(() => {
        setErrorMsg({})
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [errorMsg])

  useEffect(() => {
    performInitialChecks()
    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      
      // Call Radar's Reverse Geocoding API
      fetch(`https://api.radar.io/v1/geocode/reverse?coordinates=${latitude},${longitude}`, {
        method: 'GET',
        headers: {
          'Authorization': 'prj_live_pk_c87b1a406cf3e94c1c88a05c21e3fde0145bf9d6',
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data && data.addresses && data.addresses.length > 0) {
            const legalStates = ['AK', 'CA', 'GA', 'IL', 'KY', 'MN', 'NC', 'NE', 'NM', 'ND', 'OK', 'OR', 'RI', 'SC', 'SD', 'TX', 'UT', 'WA', 'DC', 'WI'];
            const state = data.addresses[0]?.stateCode ? data.addresses[0]?.stateCode : ""
            setValidLocation(legalStates.includes(state));
            setDetectedState(state)
          } else {
            const sendData = async () => {
              try {
                if (session && session?.access_token) {
                  const userData = {
                    info: `Error getting location3: No address found for the location.`,
                    info2: 'NA',
                    info3: 'NA',
                    token: session.access_token
                  }
                  const response2 = await sendLocationData2(userData);
                }
              } catch (err) {
                console.error('Error sending location data:', err);
              }
            }
            sendData()
          }
        })
        .catch(error => {
          console.error('Error getting location2:', error);
          const sendData = async () => {
            try {
              if (session && session?.access_token) {
                const userData = {
                  info: `Error getting location2: ${error?.message}`,
                  info2: error?.param,
                  info3: error?.code,
                  token: session.access_token
                }
                const response2 = await sendLocationData2(userData);
              }
            } catch (err) {
              console.error('Error sending location data:', err);
            }
          }
          sendData()
          setValidLocation(undefined);
        });
    };

    const handleError = async (error) => {
      try {
        if (session && session?.access_token) {
          const userData = {
            info: `Error getting location: ${error?.message}`,
            info2: 'NA',
            info3: 'NA',
            token: session.access_token
          }
          const response2 = await sendLocationData2(userData);
          console.log(response2)
          if (response2?.code === "BYPASS") {
            setValidLocation(true)
            setDetectedState('CA')
          }
        }
      } catch (err) {
        console.error('Error sending location data:', err);
        setValidLocation(undefined);
      }
    };

    if (session && session?.access_token && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      setValidLocation(undefined);
    }
  }, [session]);

  useEffect(() => {
    if (session && session?.access_token) {
      const sendData = async () => {
        const userData = {
          location: detectedState,
          token: session.access_token
        }
  
        try {
          const response2 = await sendLocationData(userData);
        } catch (error) {
          console.error('Error sending location data:', error);
        }
      }
  
      sendData()
    }
  }, [detectedState, session])

  const { checkCacheStatus } = useCacheBuster();

  // Use the effect to automatically check cache every 20 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkCacheStatus()
    }, 1200000) // 1200000 ms = 20 minutes

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [checkCacheStatus]);

  return (
    <div className="App">

      {errorMsg && errorMsg.msg ? 
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
          <Alert sx={{ 
            position: 'fixed', top: '100px', width: {xs:'80%', lg:'auto'},
            zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
            border: '2px solid #d32f2f', color: 'white', 
            fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
            alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
          }} severity="error" >
            {errorMsg?.msg}
          </Alert> 
        </Box>
      : null }

      {
        false && session && !validLocation && detectedState !== '?' ? 
          <>
            <LocationVerificationDialog open={!validLocation && detectedState !== '?' }/>
          </>
        :
          null
      }

      <Box sx={{ display: 'flex', boxSizing: 'border-box', minHeight: '100vh', mr: {xs:'8px', sm:'12px', md:'12px'}, ml: {xs:'8px', sm:'12px', md:'16px', lg: 0} }} >
        {!isAdminRoute && <NavBar2 session={session} setSession={setSession} />}
        {!isAdminRoute && <NavBar session={session} setSession={setSession} promoBalance={promoBalance} setPromoBalance={setPromoBalance} setOpen={setOpen} isVerified={isVerified} />}
        <Routes>
          <Route path='/' exact element={<BoardPage session={session} setSession={setSession} validLocation={validLocation} detectedState={detectedState} />} />
          <Route path='/login' exact element={<LoginPage session={session} setSession={setSession} setIsVerified={setIsVerified} linkedLogin={linkedLogin} />} />
          <Route path='/board' exact element={<BoardPage session={session} setSession={setSession} validLocation={validLocation} detectedState={detectedState} />} />
          <Route path='/myentries' exact element={session ? <EntriesPage session={session} setSession={setSession} promoBalance={promoBalance} setPromoBalance={setPromoBalance} open={open} setOpen={setOpen} isVerified={isVerified} /> : <RedirectPage /> } />
          <Route path='/promotions' exact element={session ? <PromotionsPage session={session} setSession={setSession} /> : <RedirectPage /> } />
          <Route path='/settings' exact element={session ? <SettingsPage session={session} setSession={setSession} setIsVerified={setIsVerified} validLocation={validLocation} detectedState={detectedState} /> : <RedirectPage /> } />
          <Route path='/admin' exact element={<AdminPage />} />
          <Route path='/howtoplay' exact element={<HowToPlayPage session={session} setSession={setSession} />} />
          {/* Catch-All Route */}
          <Route path='*' element={<Navigate to="/" />} />
        </Routes>
      </Box>
    </div>
  );
}

export default App;


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { ReactComponent as COD } from '../images/COD.svg';
import { ReactComponent as HALO } from '../images/HALO.svg';
import { ReactComponent as WZ } from '../images/WZ.svg';
import { ReactComponent as CS2 } from '../images/CS2.svg';
import { ReactComponent as VAL } from '../images/VAL.svg';
import { ReactComponent as APEX } from '../images/APEX.svg';
import { ReactComponent as LOL } from '../images/LOL.svg';
import { ReactComponent as RL } from '../images/RL.svg';
import { ReactComponent as DOTA2 } from '../images/DOTA2.svg';
import { ReactComponent as R6 } from '../images/R6.svg';

function GameBtn(props) {

    const gameComponents = {
        cod: COD,
        halo: HALO,
        wz: WZ,
        cs2: CS2,
        val: VAL,
        apex: APEX,
        lol: LOL,
        rl: RL,
        dota2: DOTA2,
        r6: R6
    };

    // Select the correct component based on props.gameTitle
    const SelectedGameComponent = gameComponents[props.gameTitle.toLowerCase()] || COD;

    return (
        <>
            <Button
                variant='outlined'
                size='large'
                onClick={() => {
                    props.setGame(props.gameTitle)
                }}
                sx={{
                    width: {xs:'112px', md:'144px'},
                    height: {xs:'44px', md:'64px'}, 
                    minWidth: {xs:'112px', md:'144px'},
                    minHeight: {xs:'44px', md:'64px'},
                    fontWeight: 'bold', 
                    fontFamily: 'Poppins',
                    lineHeight: 1,
                    display: 'flex', 
                    fontSize: {xs:'16px', md:'18px'},
                    color: (props.game === props.gameTitle) ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                    borderRadius: {xs:'10px', md:'20px'},
                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                    pr: (props.gameTitle === "WZ" || props.gameTitle === "CS2" || props.gameTitle === "R6") ? {xs:'32px', md:'36px'} : {xs:'26px', md:'30px'},
                    boxShadow: (props.game === props.gameTitle) ? 'inset 0px 0px 20px rgba(168, 0, 0, 0.4)' : 'none',
                    border: (props.game === props.gameTitle) ? '1px solid rgba(168, 0, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)',
                    ':hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        boxShadow: 'inset 0px 0px 24px rgba(168, 0, 0, 0.7)',
                        color: 'rgba(168, 0, 0, 1)',
                        border: '1px solid rgba(168, 0, 0, 0.5)'
                    },
                }}
            >
                <Box sx={{ maxWidth: '48px', minWidth: '48px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SelectedGameComponent />
                </Box>
                {props.gameTitle}
            </Button>
        </>
    );
}
    
export default GameBtn;
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

function EntrySummarySkeleton(props) {
  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', height: '100%', boxSizing: 'border-box', alignItems: 'center' }}>
        <Skeleton variant="rounded" sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            width: '100%',
            height: {xs: '132px', sm: '140px'},
            my: {xs: '8px', sm: '14px'}
        }} />
        <Skeleton variant="rounded" sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            width: '100%',
            height: {xs: '132px', sm: '140px'},
            mt: {xs: '8px', sm: '14px'}
        }} />
        <Box sx={{ display: 'flex', width: '100%', height: '36px', flexDirection: 'row', justifyContent: 'space-between', mt: {xs: '8px', sm: '14px'}, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: {xs: '32px', sm: '36px'}, mb: {xs: '-12px', sm: '-24px'} }}>
            <h1 style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '18px', color: 'rgba(255, 255, 255, 0.1)' }} >AGENTPICKS</h1>
          </Box>
          <Skeleton variant="rounded" sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              width: {xs: '115px', sm: '150px'},
              height: {xs: '18px', sm: '24px'},
              mb: {xs: '-12px', sm: '-24px'}
          }} />
        </Box>
    </Box>
  )
}
export default EntrySummarySkeleton
import { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import PasswordTextField from "./PasswordTextField";

import { supabaseLogin } from '../auth/authHelpers';


function AdminLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState({});

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event) =>{
    event.preventDefault();

    // Pre-server-side validation:
    if (!email) {
      setErrorMsg({type: "email", msg: "An email is required for login"})
      return
    } else if (!password) {
      setErrorMsg({type: "password", msg: "A password is required for login"})
      return
    }

    const userData = {
        email: email,
        password: password
    }

    const response = await supabaseLogin(userData)
    if (!response) {
        setErrorMsg({type: "other", msg: "Invalid Credentials"})
        return
    }

    props.setAdminSession(response)

    return
  }

  useEffect(() => {
    if (Object.keys(errorMsg).length > 0 && errorMsg.type === "other") {
      const timer = setTimeout(() => {
        setErrorMsg({})
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [errorMsg])

  return (
    <div className='login-page'>

      {errorMsg && errorMsg.msg && errorMsg.type === "other" ? 
        <Alert sx={{ 
          position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
          zIndex: 9999, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
          border: '2px solid #d32f2f', color: 'white', 
          fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
          alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
        }} severity="error" >
          {errorMsg?.msg}
        </Alert> 
      : null }

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', maxWidth: '100%', margin: '25px 10px',}}>
        <Typography sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 800, fontSize: {xs:'42px', sm:'48px'} }}>
          AGENTPICKS2
        </Typography>
      </Box>
      <form onSubmit={handleLogin} style={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TextField
            type="email"
            id="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            sx={{ margin: "10px", maxWidth: "350px", width: "95%",
              input: { color: 'white' },
              "& .MuiOutlinedInput-root": {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                }
              } 
            }}
            InputLabelProps={{className: "textfield__color"}}
            error={errorMsg.type === "email" ? true : false}
            helperText={errorMsg.type === "email" ? errorMsg.msg : false}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '12px' }}>
          <PasswordTextField 
            id="password"
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            sx={{ margin: "10px", maxWidth: "350px", width: "95%", 
              input: { color: 'white' },
              "& .MuiOutlinedInput-root": {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                }
              } 
            }}
            InputLabelProps={{className: "textfield__color"}}
            error={errorMsg.type === "password" ? true : false}
            helperText={errorMsg.type === "password" ? errorMsg.msg : false}
          />
        </Box>
        <div className='login-buttons'>
          <Button variant="contained" type='submit' sx={{ 
            bgcolor: 'rgba(168, 0, 0, 0.8)', 
            color: 'white',
            width: '95%',
            maxWidth: '350px',
            height: '48px',
            '&:hover': {
                backgroundColor: '#A80000',
            },  
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 700,
            fontSize: '16px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            lineHeight: 1,
            textTransform: 'none',
          }}>
              Log In
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AdminLogin;

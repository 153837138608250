import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { IoMdClose } from "react-icons/io";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

import Logo from './Logo';

import moment from 'moment-timezone';

function CartCard(props) {

    const [higherSelected, setHigherSelected] = useState(false)
    const [lowerSelected, setLowerSelected] = useState(false)

    const removePick = () => {
        const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.pickObj._id)
        props.setSelectedPicks(updatedPicks)
    }

    const handlePick = (choice) => {
        if (props.selectedPicks.length === 0) {
            props.setCartOpen(true)
        }
        // if clicking an already selected button or modifying an already selected pick
        if (higherSelected || lowerSelected || props.selectedPicks.some(pick => pick._id === props.pickObj._id)) {
            // if clicking an already selected button
            if ((higherSelected && choice === "OVER") || (lowerSelected && choice === "UNDER")) {
                const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.pickObj._id)
                props.setSelectedPicks(updatedPicks)
            } 
            // if changing from over to under or under to over
            else {
                const updatedPicks = props.selectedPicks.map(pick => {
                    if (pick._id === props.pickObj._id) {
                        return {
                            ...pick,
                            bet: choice,
                        }
                    } else {
                        return pick
                    }
                })
                props.setSelectedPicks(updatedPicks)
            }
        } 
        // if selecting pick for a the first time
        else {
            props.setSelectedPicks([...props.selectedPicks, {
                _id: props.pickObj._id,  
                name: props.pickObj.name,
                game: props.pickObj.game,
                team: props.pickObj.team,
                opp: props.pickObj.opp,
                pro: props.pickObj.pro,
                img: props.pickObj.img,
                matchDateTimeEST: props.pickObj.matchDateTimeEST,
                maps: props.pickObj.maps,
                predicted: props.pickObj.predicted,
                special: props.pickObj?.special ? props.pickObj?.special : "",
                stat: props.pickObj.stat,
                bet: choice,
            }
        ])
        }
    }

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };

    const checkIfSelected = () => {
        if (!props.selectedPicks) {
            return false
        }
        setHigherSelected(props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "OVER"))
        setLowerSelected(props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "UNDER"))
    }

    useEffect(() => {
        checkIfSelected()
    }, [props.selectedPicks])

    return (
        <Box sx={{ height: '150px', width: '100%', minWidth: '270px', bgcolor: '#23292C', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '8px', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
            <Box sx={{ height: '100px', width: '100%', boxSizing: 'border-box', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', width: '120px', p: '10px', boxSizing: 'border-box', maxWidth: '120px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', boxSizing: 'border-box' }}>
                        <Logo teamColor={props.pickObj.img} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: {xs:'100%', md:'calc(100% - 120px)'}, height: '100%', position: 'relative' }}>
                    <Button onClick={() => removePick()} sx={{
                        minWidth: 0,
                        minHeight: 0,
                        p: 0,
                        m: '5px',
                        borderRadius: 100,
                        color: 'rgba(255, 255, 255, 0.2)',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: 'rgba(255, 255, 255, 0.5)'
                        },
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}>
                        <IoMdClose size={24}/>
                    </Button>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                fontSize: props.pickObj.name.includes(' + ') ? '16px' : '18px',
                                color: 'white',
                                lineHeight: 1.1,
                                mb: '3px',
                                textAlign: 'left',
                                mt: '4px',
                                pr: '16px'
                            }}
                        >
                            {props.pickObj.name.toUpperCase()}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', bgcolor: 'rgba(255, 255, 255, 0.05)', width: '37px', height: '100%', borderRadius: '4px', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '10px', color: 'lightgray', mb: '-1px' }}>{props.pickObj.game}</Typography>
                            </Box>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '12px', color: 'rgba(255, 255, 255, 0.8)' }}>{props.pickObj.team}</Typography>
                        </Box>
                        <Box sx={{ mt: {xs:'3px', md:'4px'}, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '12px', color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2, textAlign: 'left' }}>{convertToDate(props.pickObj.matchDateTimeEST).slice(0, -5)} at {convertToTime(props.pickObj.matchDateTimeEST)}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '8px' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '12px', color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>vs {props.pickObj.opp}</Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: '12px', color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>MAP{props.pickObj.maps.length > 1 ? "S" : ""} {props.pickObj.maps}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', height: '50px', flexDirection: 'row', boxSizing: 'border-box' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '55%', alignItems: 'flex-end', pl: '10px', pb: '12px', boxSizing: 'border-box' }}>
                    <Box sx={{ height: '100%', width: '100%', bgcolor: 'rgba(255, 255, 255, 0.05)', border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '4px', alignItems: 'center', boxSizing: 'border-box', px: '6px', py: '8px' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs: '18px', md: '22px'}, color: props.pickObj?.special === "DISCOUNTED" ? 'gold' : 'white', lineHeight: 1.1 }} >{props.pickObj.predicted}</Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 300, fontSize: {xs: '12px', md: '14px'}, color: 'white', lineHeight: 1, mb: '-5px' }} >{props.pickObj.stat}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '45%', alignItems: 'flex-end', pb: '12px', px: '10px', gap: '4px' }}>
                    <Button 
                        onClick={() => handlePick('OVER')}
                        sx={{ 
                            width: props.pickObj.pro && props.pickObj.special !== 'DISCOUNTED' ? {xs:'62px', md:'64px'} : {xs:'128px', md:'132px'},
                            minWidth: props.pickObj.pro && props.pickObj.special !== 'DISCOUNTED' ? {xs:'62px', md:'64px'} : {xs:'117px', md:'132px'},
                            height: '38px',
                            borderRadius: '8px',
                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 600,
                            fontSize: {xs:'11px', md:'12px'},
                            color: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "OVER") ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                            textTransform: 'none',
                            boxShadow: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "OVER") ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                            border: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "OVER") ? '1px solid rgba(168, 0, 0, 0.8)' : 'none',
                            ':hover': {
                                boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.7)',
                                color: 'rgba(168, 0, 0, 1)',
                                border: '1px solid rgba(168, 0, 0, 0.8)'
                            },
                            '& .MuiButton-startIcon': {
                                mr: 0,
                            },
                            display: 'flex',
                            justifyContent: 'center',
                        }} 
                        startIcon={<MdExpandLess size={18} />}
                    >
                        MORE
                    </Button>
                    <Button 
                        disabled={!props.pickObj.pro}
                        onClick={() => handlePick('UNDER')}
                        sx={{
                            visibility: props.pickObj.pro && props.pickObj.special !== 'DISCOUNTED' ? 'visible' : 'hidden',
                            width: {xs:'62px', md:'64px'},
                            minWidth: {xs:'62px', md:'64px'},
                            height: '38px',
                            borderRadius: '8px',
                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 600,
                            fontSize: {xs:'11px', md:'12px'},
                            color: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "UNDER") ? 'rgba(168, 0, 0, 1)': 'whitesmoke',
                            textTransform: 'none',
                            boxShadow: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "UNDER") ? 'inset 0px 0px 16px rgba(168, 0, 0, 0.4)' : 'none',
                            border: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "UNDER") ? '1px solid rgba(168, 0, 0, 0.8)' : 'none',
                            ':hover': {
                                boxShadow: 'inset 0px 0px 20px rgba(168, 0, 0, 0.7)',
                                color: 'rgba(168, 0, 0, 1)',
                                border: '1px solid rgba(168, 0, 0, 0.8)'
                            },
                            '& .MuiButton-startIcon': {
                                mr: 0,
                            },
                            display: props.pickObj.pro && props.pickObj.special !== 'DISCOUNTED' ? 'flex' : 'none',
                            justifyContent: 'center',
                        }} 
                        startIcon={<MdExpandMore size={18} />}
                    >
                        LESS
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
    
export default CartCard;
import Persona from "persona";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import { saveVerificationInfo, sendLocationData2 } from "../APIs";

import { supabase } from '../auth/client';

function PersonaComponent(props) {
  const [options, setOptions] = useState({
    host: "production",
    templateId: "itmpl_MArEfVUQ5B3Zu8VsBCM8kD1bEwrr",
    templateVersionId: "",
    environment: "env_oFTxxeEHyeFxKmdEppusP3TK"
  });

  const navigate = useNavigate()

  const [status, setStatus] = useState("closed");

  const embeddedClientRef = useRef(null);

  const handleVerification = async (inquiryID) => {

    const userData = {
      inquiryID: inquiryID,
      token: props.session.access_token
    }

    const data = await saveVerificationInfo(userData)

    if (data.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (data.status === 400) {
      props.setErrorMsg({type: "other", msg: data?.errorObj?.msg})
      return
    } else if (data.status !== 200) {
      props.setErrorMsg({type: "other", msg: "Server error during ID verification"})
      return
    }

    // is verified true only if in valid state
    props.setIsVerified(true)
    props.setUpdated(!props.updated)

    return
  }

  /**
   * Creates a new instance of the Persona client. Note that if this is called
   * when the popup is closed, an entirely new inquiry will be created (as
   * opposed to if you wre to call client.open()).
   */
  const createClient = () => {
    setStatus("loading");
    const client = new Persona.Client({
      host: options.host,
      templateId: options.templateId,
      templateVersionId: options.templateVersionId,
      environment: options.environment,
      referenceId: props.session.user.user_metadata._id,
      onLoad: () => {
        setStatus("loaded");
        // If calling client.open() here, widget will open sooner and show a loading state.
        // client.open();
      },
      onReady: () => {
        setStatus("ready");
        // If calling client.open() here, widget will open later and be immediately ready to use.
        // This should be done if loading state is handled externally.
        client.open();
      },
      onComplete: ({ inquiryId, status, fields }) => {
        if (status === 'completed') {
          handleVerification(inquiryId)
        }
      },
      onError: (error) => {
        // DEBUGGING STUFF
        try {
          if (props.session?.access_token) {
            const userData = {
              info: `Error with verification onError: ${error?.message}`,
              info2: `Error with verification onError2: ${error}`,
              info3: `Error with verification onError3: ${error?.code}`,
              token: props.session?.access_token
            }
            sendLocationData2(userData);
          }
        } catch (err) {
          const userData = {
            info: `Error with verification onError4: ${error}`,
            info2: `Error with verification onError5: ${err}`,
            info3: 'NA',
            token: props.session?.access_token
          }
          sendLocationData2(userData);
        }
        props.setErrorMsg({type: "ID Verification", msg: "Server error during ID verification2"})
      }
    });
    embeddedClientRef.current = client;

    /*
    window.cancel = (force) => {
      if (client) {
        client.cancel(force);
        setStatus("closed");
      } else {
        alert("Initialize client first");
      }
    };
    */
  };

  return (
    <>
      {embeddedClientRef.current == null ? (
        <Button onClick={createClient} disabled={status !== "closed"} variant="contained" sx={{ 
          bgcolor: 'rgba(168, 0, 0, 0.8)', 
          color: 'white',
          width: '70px',
          height: '30px',
          '&:hover': {
            backgroundColor: 'rgba(168, 0, 0, 1)',
          },
          fontFamily: 'Plus Jakarta Sans',
          fontWeight: 700,
          fontSize: '12px',
          borderRadius: '8px',
          boxSizing: 'border-box',
          lineHeight: 1,
          px: '4px'
        }}>
          Verify
        </Button>
      ) : (
        <Button onClick={() => embeddedClientRef.current.open()}variant="contained" sx={{ 
          bgcolor: 'rgba(168, 0, 0, 0.8)', 
          color: 'white',
          width: '70px',
          height: '30px',
          '&:hover': {
            backgroundColor: 'rgba(168, 0, 0, 1)',
          },
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: '12px',
          borderRadius: '8px',
          boxSizing: 'border-box',
          lineHeight: 1
        }}>
          Verify
        </Button>
      )}
    </>
  );
}

export default PersonaComponent;

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";


function HowToPlayPage() {

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                minHeight: '100vh', 
                width: {
                    xs: '100%', 
                    lg: 'calc(100% - 300px)', 
                    xl: 'calc(100% - 320px)'
                },
                mt: {xs:'78px', md:'104px'},
                boxSizing: 'border-box',
                alignItems: 'center', minWidth: '300px',
                pb: '130px',
                pr: {
                xs: '0px',
                lg: '300px', 
                xl: '320px'
                },
            }}
        >
            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '32px', color: 'white' }} >HOW TO PLAY</Typography>
            <Box sx={{ display: 'flex', width: {xs:'98%', sm:'90%'}, maxWidth: '800px' , mt: '48px', gap: '16px', flexDirection: 'column'}}>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >1. Sign Up on EsportsAgent.gg</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo1.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >2. Log In on AgentPicks</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo2.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >3. Verify Your Identity</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo3.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >4. Complete Your Deposit</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo4.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >5. Pick 2-6 Player Over/Unders</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo5.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >6. Choose Your Entry Amount</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo6.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >7. Submit Your Picks and Win BIG!</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo7.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '28px', color: 'white', lineHeight: 1.2, mt: '48px' }} >FULL TUTORIAL</Typography>
                <div className="video-responsive">
                    <iframe
                        src={`https://www.youtube.com/embed/QKzpWeXvZCQ`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded YouTube"
                    ></iframe>
                </div>
            </Box>
        </Box>
    )
}
  
export default HowToPlayPage;
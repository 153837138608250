import { useState, useEffect } from 'react';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import ProgressBar from './ProgressBar';
import Logo from './Logo';

import moment from 'moment-timezone';

function EntrySummaryCard(props) {

    let win = 0

    // if finished and actual and correctly bet over and not DNP: win
    if (props.pickObj.finished === true && props.pickObj.actual !== null && props.pickObj.actual > props.pickObj.predicted && props.pickObj.bet === "OVER" && props.pickObj.actual !== -1) {
        win = 1
    } 
    // if finished and actual and correctly bet under and not DNP: win
    else if (props.pickObj.finished === true && props.pickObj.actual !== null && props.pickObj.actual < props.pickObj.predicted && props.pickObj.bet === "UNDER" && props.pickObj.actual !== -1) {
        win = 1
    } 
    // if no actual, DNP, or push: no win
    else if (props.pickObj.actual === null || (props.pickObj.finished === true && props.pickObj.actual === -1) || (props.pickObj.finished === true && props.pickObj.actual === props.pickObj.predicted)) {
        win = 0
    } 
    // if finished: loss
    else if (props.pickObj.finished === true) {
        win = -1
    }

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };

    return (
        <Box sx={{ height: '150px', width: '100%', borderRadius: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxSizing: 'border-box', my: {xs:0, sm:'8px'} }}>
            <Box
                sx={{
                    display: 'flex', // Default display
                    justifyContent: 'center',
                    pr: { xs: '6px', sm: '12px' },
                    height: '70%',
                    width: '132px',
                    boxSizing: 'border-box',
                    minWidth: '40px',
                    '@media (max-width: 380px)': {
                        visibility: 'hidden',
                        pr: '0',
                        width: '0',
                        minWidth: '16px'
                    },
                }}
            >
                <Logo teamColor={props.pickObj.img} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ 
                        fontFamily: 'Poppins', fontWeight: 600, color: 'white',
                        fontSize: {
                            xs: props.pickObj.name.includes(' + ') ? '14px' : '16px',
                            sm: props.pickObj.name.includes(' + ') ? '18px' : '18px',
                        },
                        lineHeight: 1.1, mb: '3px', 
                        textAlign: 'left', mt: '4px',
                        minWidth: '140px'
                    }}>
                        {props.pickObj.name.toUpperCase()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', bgcolor: 'rgba(255, 255, 255, 0.05)', width: '37px', height: '100%', borderRadius: '4px', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'10px', sm:'12px'}, color: 'lightgray', mb: '-1px' }}>{props.pickObj.game}</Typography>
                        </Box>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'13px', sm:'15px'}, color: 'rgba(255, 255, 255, 0.8)' }}>{props.pickObj.team}</Typography>
                    </Box>
                    <Box sx={{ mt: '6px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', sm:'13px'}, color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2, textAlign: 'left' }}>{convertToDate(props.pickObj.matchDateTimeEST).slice(0, -5)} at {convertToTime(props.pickObj.matchDateTimeEST)}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '8px' }}>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', sm:'13px'}, color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>vs {props.pickObj.opp}</Typography>
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'11px', sm:'13px'}, color: 'rgba(255, 255, 255, 0.6)', lineHeight: 1.2 }}>MAP{props.pickObj.maps.length > 1 ? "S" : ""} {props.pickObj.maps}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: '5px', display: 'flex', flexDirection: 'row', justifyContent: {xs:'flex-end', sm:'center'}, width: '100%', alignItems: 'center', gap: '5px', ml: {xs: '-20px', sm: 0} }}>
                    <ProgressBar actual={props.pickObj.actual} predicted={props.pickObj.predicted} win={win} expired={props.pickObj.expired} finished={props.pickObj.finished} />
                </Box>
            </Box>
            {props.pickObj.actual !== null && props.pickObj.actual === -1 ?
                <Box sx={{ display: 'flex', justifyContent: 'center', boxSizing: 'border-box', alignItems: 'center'  }}>
                    <Box sx={{ height: {xs:'86px', sm:'120px'}, width: {xs:'86px', sm:'120px'}, bgcolor: {xs:'rgba(255, 255, 255, 0.05)', md:'#23292C'}, borderRadius: '4px', border: '1px solid rgba(255, 255, 255, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box' }}>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'20px', sm:'28px'}, color: "white" }}>DNP</Typography>
                    </Box>
                </Box>
            :
                <Box sx={{ display: 'flex', justifyContent: 'center', boxSizing: 'border-box', alignItems: 'center'  }}>
                    <Box sx={{ height: {xs:'86px', sm:'120px'}, width: {xs:'86px', sm:'120px'}, bgcolor: {xs:'rgba(255, 255, 255, 0.05)', md:'#23292C'}, borderRadius: '4px', border: '1px solid rgba(255, 255, 255, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box' }}>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: {xs:'14px', sm:'16px'}, lineHeight: {xs: 1.2, sm: 1.2}, color: 'white' }}>{props.pickObj.bet === "OVER" ? "MORE" : "LESS"}</Typography>
                        {props.pickObj.stat === "DMG" ? 
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs: props.pickObj.predicted.toString().length > 4 ? '20px' : '22px', sm: props.pickObj.predicted.toString().length > 4 ? '30px' : '32px'}, lineHeight: {xs: 1.2, sm: 1.4}, color: win === 1 ? 'rgb(28, 197, 0)' : win === 0 ? "white" : "rgba(168, 0, 0, 0.8)" }}>{props.pickObj.predicted}</Typography>
                        :
                            <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 600, fontSize: {xs: props.pickObj.predicted.toString().length > 4 ? '20px' : '22px', sm: props.pickObj.predicted.toString().length > 4 ? '30px' : '32px'}, lineHeight: {xs: 1.2, sm: 1.4}, color: win === 1 ? 'rgb(28, 197, 0)' : win === 0 ? "white" : "rgba(168, 0, 0, 0.8)" }}>{props.pickObj.predicted.toString().includes('.') ? props.pickObj.predicted : `${props.pickObj.predicted}.0`}</Typography>
                        }
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, lineHeight: {xs: 1.0, sm: 1.5}, color: 'white' }}>{props.pickObj.stat}</Typography>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, lineHeight: {xs: 1.2, sm: 1.5}, color: 'white' }}>MAP{props.pickObj.maps.length > 1 ? "S" : ""} {props.pickObj.maps}</Typography>
                    </Box>

                </Box>
            }
        </Box>
    );
}
    
export default EntrySummaryCard;